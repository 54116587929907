import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import { fetchRecentlyWatched, loginModelOpen, start_loader, stop_loader, joinClass } from "../../actions";
import moment from 'moment';
import { commonService } from "../../_services";
import JoinClassComponent from "../LiveClasses/LiveClassJoinModel";
import { ImageTag } from "../../tags";
import WorkoutSaveComponent from "../WorkoutsPage/workoutSaveComponent";
import WorkoutScheduleComponent from "../WorkoutsPage/WorkoutScheduleComponent";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";

class HistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: false,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            historyList: [],
            condition: this.props.params.condition,
            modelState: false,
            modelData: {},
            total_tags: [],
            total_levels: []
        };
        this.modelClose = this.modelClose.bind(this);
        this.fetchLiveClassList = this.fetchLiveClassList.bind(this);
    }


    modelClose() {
        this.setState({ modelState: false, modelData: {} });
    }
    /** fetch live class list on page did mount */
    componentDidMount() {
        this.setState({ condition: this.state.condition }, () => {
            this.fetchLiveClassList();
        })
    }
    componentWillReceiveProps(prevProps) {
        if (this.props.params && prevProps.params.condition && this.props.params.condition !== prevProps.params.condition) {
            var condition = commonService.replaceChar(prevProps.params.condition, true)
            this.setState({ condition: condition }, () => {
                this.fetchLiveClassList();
            })
        }
    }
    /**
     * fetch class list data
     */
    fetchLiveClassList() {
        this.setState({ Loading: true });
        var condition = this.state.condition;
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": condition
        };
        this.props.start_loader();
        this.props.fetchRecentlyWatched(dataObj).then(
            response => {
                this.props.stop_loader();

                if (response) {
                    var list = response.list;
                    if (list && list.history) {
                        this.groupListResult(list.history);
                    }
                    this.setState({
                        Loading: false,
                        count: response.count,
                        total_tags: (response.list.tags) ? response.list.tags : [],
                        total_levels: (response.list.levelTags) ? response.list.levelTags : []
                    }, () => {
                    });

                }
            },
            error => {
                this.props.stop_loader();
                this.setState({
                    Loading: false
                });
                //toast.error(error);
            }
        );
    }
    /**
     * get day name
     */
    getDayNameFromDate(date) {
        var dt = moment(date, "YYYY-MM-DD")
        var day = moment(date).format('dddd');
        var monthName = moment(date).format('MMMM');
        var weekDay = moment(date).format('DD');
        weekDay = weekDay < constand.CONSTTEN ? weekDay : weekDay;
        var fromNow = moment(date).fromNow();
        var watched = moment(date).calendar(null, {
            // when the date is closer, specify custom values
            lastWeek: '[Last] dddd',
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            // when the date is further away, use from-now functionality
            sameElse: function () {
                return "[" + fromNow + "]";
            }
        })
        return watched + " - " + dt.format('dddd') + ' ' + monthName + ' ' + weekDay;
    }


    /**
     * render item list
     */
    renderSubItemList(data, key) {
        return data.map((item, index) => (

            <div className="list-group-item" key={index}>
                <div className="col-md-12 desktop-view-live">
                    <div className="row">
                        <div className="media col-md-3 col-lg-3 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={()=>{Cookies.set('workoutId',item.id)}}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-md-9 col-lg-9 p-0">
                            <div className="col-md-9 col-lg-9 col-sm-9 float-left pl-20">

                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left m-b-10 p-0">
                                    <div className="p-0 border-0 float-left w-100">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES+'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={()=>{Cookies.set('workoutId',item.id)}}> {item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                  <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + (commonService.replaceChar(this.props.params.condition, false))} className="font-16 font-semibold black-txt p-l-5">
                                                {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Discipline:
                              </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "discipline", this.state.total_tags, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Difficulty:
                            </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "level", this.state.total_levels, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 float-left col-md-3 p-0 text-center">
                                {item.type != 'Live' &&
                                    <a className="btn btn-purple w-100 font-medium m-b-10" href={"/detail/" + item.Workout.id + "/" + (commonService.replaceChar(this.props.params.condition, false))}>Do Again</a>
                                }

                                <span
                                    className="btn btn-default-list-blue clearfix pad_list_btn"
                                >
                                    {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                                </span>
                                {item.type == 'Live' && <span className="btn btn-blue-inverse w-100 btn-white redtext bg-white font-medium" >Live session</span>}

                            </div>
                            {item.type != 'Live' &&
                                <div className="col-md-3 col-lg-3 col-sm-3 btngroup bookmark-bottom--align float-left pl-0 pr-0">
                                    <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                                        <div className="bookmark-left float-right text-right align-self-end">
                                            <b className="m-r-10">
                                                <WorkoutSaveComponent
                                                    className="m-l-10"
                                                    page="recently_watched"
                                                    workoutData={item.Workout}
                                                />
                                            </b>
                                            <WorkoutScheduleComponent
                                                ondemand_data={item.Workout}
                                                location={this.props.location}
                                                history={this.props.history}
                                                pageName="recently_watched"
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* mobile view */}
                <div className="col-md-12 mobile-view-live">
                    <div className="row">
                        <div className="media col-12 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={()=>{Cookies.set('workoutId',item.id)}}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-12 p-0">
                            <div className="col-12 float-left p-0 ">
                                <div className="col-12  float-left p-0">
                                    <div className="p-0 border-0 float-left w-100 m-t-5 m-b-5">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES+'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={()=>{Cookies.set('workoutId',item.id)}}>{item.Workout.title}</Link>
                                        </div><div className="w-80">with
                              <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + (commonService.replaceChar(this.props.params.condition, false))} className="font-16 font-semibold black-txt p-l-5">
                                                {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="font-medium col-12 p-0 m-b-10 float-left ">
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Discipline:
                                  </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "discipline", this.state.total_tags, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Difficulty:
                                  </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "level", this.state.total_levels, item.WorkoutId
                                            )}
                                        </span>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="col-12 float-left p-0 text-center">

                            {item.type != 'Live' &&
                                <a className="btn btn-purple w-100 font-medium m-b-10" href={"/detail/" + item.Workout.id + "/" + (commonService.replaceChar(this.props.params.condition, false))}>Do Again</a>
                            }
                            <span
                                className="btn btn-default-list-blue clearfix pad_list_btn"
                            >
                                {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                            </span>
                            {item.type == 'Live' && <span
                                className="btn btn-default-list-orange clearfix pad_list_btn"
                            >
                                Live Session
                          </span>}
                        </div>
                        {item.type != 'Live' &&
                            <div className="col-md-3 col-lg-3 col-sm-3 btngroup bookmark-bottom--align float-left pl-0 pr-0">
                                <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                                    <div className="bookmark-left float-right text-right align-self-end">
                                        <b className="m-r-10">
                                            <WorkoutSaveComponent
                                                className="m-l-10"
                                                page="recently_watched"
                                                workoutData={item.Workout}
                                            />
                                        </b>
                                        <WorkoutScheduleComponent
                                            ondemand_data={item.Workout}
                                            location={this.props.location}
                                            history={this.props.history}
                                            pageName="recently_watched"
                                        />

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        ));
    }
    /**
     * render live class list
     */
    renderListView() {
        return this.state.historyList.map((item, index) => (
            <div key={index}>
                <p className="section_header font-semibold">
                    {commonService.getDayNameFromDate(item[0].scheduledFor)}
                </p>
                {this.renderSubItemList(item, index)}
            </div>
        ));
    }
    /**
   * render start now or go to class
   */
    renderSchedule(item) {

        if (item.reference && (item.reference.toLowerCase() === 'coming soon')) {
            return (
                <a className="btn-purple pad_list_btn clearfix"> {item.reference} </a>
            );
        } else {
            /* if (item.Signedup) {
                return (
                    <a className="btn btn-purple pad_list_btn clearfix poniter" href={item.reference} target="_blank"> Go to room </a>
                );
            }
            else {
                return (
                    <a onClick={() => this.submitStartNow(item)} className="btn btn-purple pad_list_btn clearfix pointer" > Sign up </a>
                );
            } */
        }
    }
    /**
     * render load more option
     */
    renderLoadMore() {
        if (this.state.historyList && this.state.count > this.state.historyList.length) {
            return (
                <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                    <span
                        onClick={() => { this.incrementShowmoreCnt() }}
                        className="btn btn-orange m-t-40 m-b-40 font-book pointer"
                    >
                        Show more recently watched
                    </span>
                </div>
            );
        }
    }

    /**
    * render list
    */
    renderListData() {
        if (this.state.historyList && this.state.historyList.length > 0) {
            return (
                <div>

                    {this.renderListComponent()}
                </div>
            );
        }
        return (
            <div className="list-group" >
                <h3 className="text-center">You're yet to complete a class on Beam - but you're just a few clicks away from changing that...!</h3>
                <br />
                {/* <p className="text-center">(We're adding new live exercise classes regularly so be sure to check back soon!)</p> */}
            </div>
        )

    }
    /**
     * load more class list
     */
    incrementShowmoreCnt() {
        this.setState({
            offset: this.state.offset + constand.CLASS_LIST_CNT
        }, function () {
            this.fetchLiveClassList();
        });
    }
    /**
     * for pagination result grouping
     */
    groupListResult(originalList) {

        let classList = [];
        let existingData = this.state.historyList;
        let newData = originalList;
        var helper = {};
        let results = [];
        newData.map(function (item, key) {
            if (existingData && existingData.length > 0 && newData && item && moment(existingData[existingData.length - 1][0].scheduledFor).format("DD/MM/YYYY") === moment(item.scheduledFor).format("DD/MM/YYYY")) {
                //if (existingData && existingData.length > 0 && newData && item && existingData[existingData.length - 1][0].scheduledFor === item.scheduledFor) {
                existingData[existingData.length - 1].push(item);
                classList = existingData;
            } else {
                var key = item.scheduledFor;
                if (!helper[key]) {
                    helper[key] = [];
                    helper[key].push(item)
                    results.push(helper[key]);
                }
                else {
                    helper[key].push(item)
                }
                classList = [...existingData, ...results];
            }
        });
        this.setState({ historyList: classList })
    }
    /**
     * renderListComponent
     */
    renderListComponent() {
        return (
            <div id="products" className="list_section float-left w-100 p-t-20 p-b-20">
                <div className="container">
                    {this.renderListView()}
                </div>
                {this.renderLoadMore()}
            </div>
        );
    }

    //main render
    render() {
        return (
            <div className=""
            >
                <Helmet>
                    <title>{constand.DASHBOARD_HISTORY_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.DASHBOARD_HISTORY_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.DASHBOARD_HISTORY_DESC} />
                    <meta property="og:image" content={constand.DASHBOARD_HISTORY_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.DASHBOARD_HISTORY_PAGE_IMAGE_ALT} />
                </Helmet>
                {!this.state.Loading && this.renderListData()}
                <div className="text-center w-100">
                    {(this.state.Loading) && (<AnimateLoaderComponent />)}
                </div>
                <JoinClassComponent is_model_open={this.state.modelState} modelData={this.state.modelData} modelClose={this.modelClose} />

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth
    };
};

const mapDispatchToProps = {
    fetchRecentlyWatched, loginModelOpen, start_loader, stop_loader, joinClass
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryComponent);
