import React from "react";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login";
import * as constand from "../../constant";
import MultiSelectComponent from "../../_layoutComponents/MultiSelectComponent";
import axios from "axios";
import _ from 'lodash';
import {
    getInstTags,
    getBeamUser,
    ondemandCMSModelOpen,
    createNewTag,
    removeTag,
    clearInstructorList,
    imageCropOpenPopup,
    getUserForStudyInstructor
} from "../../actions";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie-consent";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import ImageCropperComponent from "../Common/ImageCropperComponent";
import { commonService } from "../../_services";
import RichTextEditor from 'react-rte';
import {CKEditor} from 'ckeditor4-react';

const Option = props => {
    return (
        <div>
            <div className={props.data.tagCount == 0 && props.selectProps.isRemoveable ? "pull-left optionWidth" : "pull-left w-100"}>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label} {props.value != '*' && props.selectProps.isRemoveable && <span className="black-txt font-semibold">({props.data.tagCount})</span>}</label>
                </components.Option>
            </div>
            {props.data.tagCount === 0 && <button onClick={() => props.selectProps.removeTag(props.value, props.selectProps.name)} className="btn pull-right orangefont removeTagBtn">X</button>}
        </div>
    );
};

const allOption = {
    label: "Select all",
    value: "*"
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label}`;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All is selected";
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};
const animatedComponents = makeAnimated();
const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'IMAGE_BUTTON'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};
class InstructorCMSComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorValue: RichTextEditor.createEmptyValue(),
            submitted: false,
            loading: false,
            isCropped: false,
            error: "",
            typeFilter: this.props.init_condition,
            instructor: {
                title: '',
                speciality: '',
                discipline: '',
                qualification: '',
                feelGoodFormula: '',
                location: '',
                bio: '',
                about: '',
                twitterLink: '',
                instagramLink: '',
                facebookLink: '',
                webLink: '',
                tiktokLink: '',
                linkedinLink: '',
                isLive: 0
            },
            isLive: 1,
            workoutId: '',
            disciplineOptions: [],
            conditionOptions: [],
            benefitOptions: [],
            equipmentOptions: [],
            levelOptions: [],
            userOptions: [],
            recordingofOptions: [],
            optionSelected: {
                'discipline': [],
                'userid': [],
                'condition': [],
                'instructorUser': []
            },
            videoUrlError: false,
            crop: {
                unit: "px", // default, can be 'px' or '%'
                x: 0,
                y: 0,
                width: 200,
                height: 200,
                aspect: 1,
                disabled: true,
                locked: true
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTextEdit = this.handleChangeTextEdit.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.handleBeamUserChange = this.handleBeamUserChange.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.clearState = this.clearState.bind(this);
        this.onCloseModel = this.onCloseModel.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.removeTag = this.removeTag.bind(this);
    }
    componentDidMount() {
        var instructors = this.props.instructor_detail;
        console.log('cmsdidmount', instructors)
        var instructorId = this.props.instructor_detail ? this.props.instructor_detail.id : '';
        var UserId = this.props.instructor_detail ? this.props.instructor_detail.UserId : '';

        this.setState({ instructor: instructors, instructorId: instructorId, editorValue: RichTextEditor.createValueFromString(instructors.bio, 'html') })
        setTimeout(() => {
            console.log('Latetime-desc')
            this.setState({ about: instructors.bio })
        }, 1500);
        if(this.props.logged_userData.isStudyLeader) {
            this.setState({
                instructor: {
                    ...this.state.instructor,
                    isLive: true,
                    hasProfile: false
                },
            });
        }       
        this.props.getInstTags('discipline', instructorId).then(
            response => {
                if (response) {
                    if (response.data.editTags.length > 0) {
                        var editTags = response.data.editTags;
                        var newOptions = this.state.optionSelected;
                        editTags.forEach(element => {
                            newOptions['discipline'].push(element.Tag);
                        });
                        this.setState({
                            optionSelected: newOptions
                        });
                    }
                    this.setState({ disciplineOptions: response.data.tags })
                }
            },
            error => { })

        this.props.getInstTags('condition', instructorId).then(
            response => {
                if (response) {
                    if (response.data.editTags.length > 0) {
                        var editTags = response.data.editTags;
                        var newOptions = this.state.optionSelected;
                        editTags.forEach(element => {
                            newOptions['condition'].push(element.Tag);
                        });
                        this.setState({
                            optionSelected: newOptions
                        });
                    }
                    if (!instructorId) {
                        var newOptions = this.state.optionSelected;
                        var newArra = _.filter(response.data.tags, function (condition) {
                            return condition.label === commonService.replaceChar(Cookies.get('condition'), true);
                        });
                        newOptions['condition'] = newArra;
                        this.setState({ optionSelected: newOptions })
                    }
                    this.setState({ conditionOptions: response.data.tags });
                    var ids = _.map(this.state.optionSelected['condition'], 'value');
                    console.log('ids-condition', ids)
                    this.getInstructor(UserId, ids);
                }
            },
            error => {
            })

        /* this.props.getBeamUser(UserId).then(
            response => {
                if (response) {
                    if (response.data.editUser) {
                        var editUser = response.data.editUser;
                        var newOptions = this.state.optionSelected;
                        newOptions['userid'] = editUser;
                        this.setState({
                            optionSelected: newOptions
                        });
                        console.log('optionSelected', this.state.optionSelected)
                    }
                    this.setState({ userOptions: response.data.users })
                }
            },
            error => {

            }
        ) */

    }


    getInstructor(instructorId, ids) {
        this.props.getBeamUser(instructorId, ids,'').then(
            response => {
                if (response) {                   
                    // var newOptions = this.state.optionSelected;
                    // newOptions['instructorUser'] = null;
                    // this.setState({
                    //     optionSelected: newOptions,
                    // });
                    if (response.data.editUser) {
                        var editUser = response.data.editUser;
                        var newOptions = this.state.optionSelected;
                        newOptions['userid'] = editUser;
                        this.setState({
                            optionSelected: newOptions
                        });
                    }
                    this.setState({ userOptions: response.data.users })
                    // this.setState({ instructorOptions: response.data.instructors })
                }
            },
            error => {
                var newOptions = this.state.optionSelected;
                newOptions['instructorUser'] = null;
                this.setState({
                    optionSelected: newOptions,
                    instructorOptions: []
                });
            }
        )
    }

    componentWillReceiveProps(nextProps) {
        let { instructor } = this.state;

        if (this.props.cropped_image != nextProps.cropped_image && nextProps.cropped_image) {
            console.log('CMS-componentWillReceiveProps', nextProps)
            this.setState({
                isCropped: true,
                instructor: {
                    ...instructor,
                    img: nextProps.cropped_image,
                    postFile: nextProps.cropped_file
                }
            })
        } else {
            this.setState({
                //isCropped: true,
                instructor: {
                    ...instructor,
                    img: nextProps.cropped_image,
                    postFile: nextProps.cropped_file
                }
            })
        }
    }
    onCloseModel() { }
    removeTag(item, type) {
        console.log('removeTags', item);
        console.log('type', type);
        var data = { type: type, tag: item }
        this.props.removeTag(data).then(
            response => {
                if (response.status) {
                    var newOptions = this.state.optionSelected;
                    var newArra = _.filter(newOptions[type], function (condition) {
                        return condition.value != item;
                    });
                    newOptions[type] = newArra;
                    this.setState({ optionSelected: newOptions });

                    var me = this;
                    switch (type) {
                        case 'condition':
                            var newOptions = _.filter(me.state.conditionOptions, function (condition) {
                                return condition.value != item;
                            });
                            me.setState({ conditionOptions: newOptions })
                            break;
                        case 'discipline':
                            var newOptions = _.filter(me.state.disciplineOptions, function (condition) {
                                return condition.value != item;
                            });
                            me.setState({ disciplineOptions: newOptions })
                            break;

                    }
                }
            },
            error => { })
    }
    
    handleChangeTextEdit = (value) => {
        console.log('handleChangeTextEdit')
        let { instructor } = this.state;
        this.setState({
            editorValue: value,
            instructor: {
                ...instructor,
                bio: value.editor.getData(),
            },
            about: value.editor.getData(),
        });

    };
    handleChange(e) {
        console.log('handleChange', e.target)
        const { name, value } = e.target;
        let { instructor } = this.state;
        switch (name) {
            case 'twitterLink':
                this.setState({ twitterLinkError: false });
                break;
            case 'facebookLink':
                this.setState({ facebookLinkError: false });
                break;
            case 'linkedinLink':
                this.setState({ linkedinLinkError: false });
                break;
            case 'instagramLink':
                this.setState({ instagramLinkError: false });
                break;
            case 'webLink':
                this.setState({ webLinkError: false });
                break;
            case 'tiktokLink':
                this.setState({ tiktokLinkError: false });
                break;
        }

        this.setState({
            videoLengthError: false,
            videoUrlError: false,
            instructor: {
                ...instructor,
                [name]: value,
            },
        });
    }
    handleMultiSelectChange(selected, type) {
        var newOptions = this.state.optionSelected;
        console.log('handleMultiSelectChange', selected)
        if (selected) {
            newOptions[type] = selected;
        }
        else {
            newOptions[type] = [];
        }
        this.setState({
            optionSelected: newOptions
        });
    };
    handleBeamUserChange(selected, type) {
        var newOptions = this.state.optionSelected;
        newOptions[type] = selected;
        this.setState({
            optionSelected: newOptions
        });
    }
    loadOptions(search, callback) {
        if (search != '') {
            var instructorId = this.props.instructor_detail ? this.props.instructor_detail.id : '';
            var ids = _.map(this.state.optionSelected['condition'], 'value');
            // this.props.getBeamUser('', search, type).then(
               this.props.getBeamUser(instructorId, ids,search).then(
                response => {
                    if (response) {
                        this.setState({ userOptions: response.data.users })
                        callback(this.state.userOptions)
                    }
                },
                error => {
                }
            )
        } else {
            console.log('LoadoptionsElse', this.state.userOptions)
            callback(this.state.userOptions)
        }
    }
    createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });
    handleCreate = (inputValue, type) => {
        //const newOption = this.createOption(inputValue);
        var newValue = this.state.optionSelected;
        var data = { type: type, tag: inputValue }
        var me = this;

        this.props.createNewTag(data).then(function (result) {
            console.log('result', result);
            var data = result.data;
            var type = data.type;
            var newOptionTag = { label: data.tag, value: data.id, tagCount: 0 }
            newValue[type].push(newOptionTag);
            me.setState({ optionSelected: newValue });

            switch (type) {
                case 'discipline':
                    var newOptions = me.state.disciplineOptions;
                    newOptions.push(newOptionTag)
                    me.setState({ disciplineOptions: newOptions })
                    break;

            }
        });
        this.setState({
            isLoading: false,
            optionSelected: newValue,
        });
    };

    handleCheckboxChange(e) //for twoway binding checkbox
    {
        const { name, value } = e.target;
        if (name == 'hasProfile') {
            var hasProfile = this.state.instructor.hasProfile;
            console.log('hasProfile', hasProfile)

            let { instructor } = this.state;

            this.setState({
                instructor: {
                    ...instructor,
                    hasProfile: !hasProfile,
                },
            });
        } else {

            var isLive = this.state.instructor.isLive;
            console.log('isLive', isLive)
            let { instructor } = this.state;

            this.setState({
                instructor: {
                    ...instructor,
                    isLive: !isLive,
                },
            });
        }

    }

    clearState() {
        this.setState({
            submitted: false,
            loading: false
        });
    }

    closeModel() { //for close the login model
        this.clearState();
        this.props.ondemandCMSModelOpen(false);
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            this.setState({ imageModel: true });
            this.props.imageCropOpenPopup(true);
            var Pic = URL.createObjectURL(event.target.files[0]);
            let { instructor } = this.state;

            this.setState({
                instructor: {
                    ...instructor,
                    postFile: event.target.files[0],
                    img: Pic
                },
            });

        }
    };
    onSubmit() { //for submit instructor
        console.log('onSubmit', this.state)
        this.setState({ submitted: true });
        const { optionSelected, instructor } = this.state;
        console.log('optionSelected-before', optionSelected.condition.length)

        // stop here if form is invalid
        if (optionSelected['userid'].length == 0 || optionSelected['condition'].length == 0) { //!instructor.title ||
            return;
        }

        if (instructor.twitterLink && commonService.checkSocialUrl(instructor.twitterLink, 'twitter')) {
            this.setState({ twitterLinkError: commonService.checkSocialUrl(instructor.twitterLink, 'twitter') })
            return;
        }
        if (instructor.facebookLink && commonService.checkSocialUrl(instructor.facebookLink, 'facebook')) {

            this.setState({ facebookLinkError: commonService.checkSocialUrl(instructor.facebookLink, 'facebook') })
            return;
        }
        if (instructor.linkedinLink && commonService.checkSocialUrl(instructor.linkedinLink, 'linkedin')) {

            this.setState({ linkedinLinkError: commonService.checkSocialUrl(instructor.linkedinLink, 'linkedin') })
            return;
        }
        if (instructor.instagramLink && commonService.checkSocialUrl(instructor.instagramLink, 'instagram')) {

            this.setState({ instagramLinkError: commonService.checkSocialUrl(instructor.instagramLink, 'instagram') })
            return;
        }
        if (instructor.webLink && commonService.checkSocialUrl(instructor.webLink, 'web')) {

            this.setState({ webLinkError: commonService.checkSocialUrl(instructor.webLink, 'web') })
            return;
        }
        if (instructor.tiktokLink && commonService.checkSocialUrl(instructor.tiktokLink, 'tiktok')) {
            this.setState({ tiktokLinkError: commonService.checkSocialUrl(instructor.tiktokLink, 'tiktok') })
            return;
        }

        this.setState({ loading: true });
        console.log('this.state.optionSelected', this.state.optionSelected)
        let postData = new FormData();
        postData.append("title", this.state.instructor.title);
        postData.append("bio", this.state.instructor.bio.toString('html').replace(/<a /g, '<a target="_blank" '));
        postData.append("speciality", this.state.instructor.speciality);
        postData.append("facebookLink", this.state.instructor.facebookLink);
        postData.append("twitterLink", this.state.instructor.twitterLink);
        postData.append("instagramLink", this.state.instructor.instagramLink);
        postData.append("linkedinLink", this.state.instructor.linkedinLink);
        postData.append("tiktokLink", this.state.instructor.tiktokLink);
        postData.append("webLink", this.state.instructor.webLink);
        postData.append("feelGoodFormula", this.state.instructor.feelGoodFormula);
        postData.append("qualifications", this.state.instructor.qualifications);
        postData.append("location", this.state.instructor.location);
        postData.append("hasProfile", this.state.instructor.hasProfile);
        postData.append("UserId", JSON.stringify(this.state.optionSelected['userid'].value));
        postData.append("condition", JSON.stringify(this.state.optionSelected['condition']));
        postData.append("discipline", JSON.stringify(this.state.optionSelected['discipline']));
        postData.append("videoImg", this.state.instructor.postFile);
        postData.append("isLive", this.state.instructor.isLive);

        console.log('postData', this.state.instructor)
        if (this.state.instructorId) {
            postData.append("instructorId", this.state.instructorId);
            var url = constand.BACKEND_URL + "/api/instructors/edit/update";
        }
        else
            var url = constand.BACKEND_URL + "/api/instructors/new/create";
        let authData;
        if (localStorage.getItem('userAuthToken')) {
            authData = JSON.parse(localStorage.getItem('userAuthToken'));
        } else {
            authData = JSON.parse(localStorage.getItem("user"));
        }
        console.log('postData', this.state);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: authData.token
            }
        };
        axios
            .post(url, postData, config)
            .then(response => {
                if (response) {
                    var resdata = response.data;
                    if (resdata.status) {
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }
                    this.props.clearInstructorList();
                    /* this.props.getList();
                    this.setState({ loading: false });
                    this.closeModel() */
                    var selectedCondition = commonService.replaceChar(this.state.optionSelected['condition'][0].label, false);
                    Cookies.set('condition', selectedCondition)
                    window.location.href = '/instructor/' + selectedCondition;
                } else {
                    this.setState({ loading: false });
                    this.closeModel()
                }

            }, error => {
                this.setState({ error: error, loading: false });
            })
    }

    render() {

        const { submitted, loading } = this.state;
        var imageModel = false;
        return (
            <React.Fragment>
                {this.state.imageModel &&
                    <ImageCropperComponent crop={this.state.crop} aspectRatio={1 / 1} width={200} height={200} src={this.state.instructor.img} text="Upload Class Thumbnail" />
                }
                <Modal
                    className="removebbg-popup"
                    open={this.props.is_cms_model}
                    onClose={this.onCloseModel}
                    center
                >
                    <div className={(Cookies.get('closeBanner') == 'false' && !this.props.close_banner) ? "modal-dialog modal-width--custom cms-model instructor-cms m-t-80" : "modal-dialog modal-width--custom cms-model instructor-cms m-t-80"} role="document">
                        <div className="modal-content">
                            <div className="modal-header header-styling">
                                <h5
                                    className="modal-title text-left col-md-11 p-0 font-semibold"
                                    id="exampleModalLabel font-medium"
                                >
                                    {this.props.cms_model_type == 'edit' ? 'Modify ' : 'Add'} Instructor
                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" onClick={this.closeModel}>
                                        &times;
                  </span>
                                </button>
                            </div>
                            <div className="modal-body body-padding--value pb-0">
                                <div className=" avatar-upload col-md-12 clearfix p-0 m-t-20 m-b-20 text-center">
                                    <div className="ondemand-preview mx-auto">

                                        {!this.state.instructor.img && <img
                                            className="img-fluid rounded-circle"
                                            src={
                                                constand.WEB_IMAGES + "instructor-placeholder.png"
                                            }
                                        />}
                                        {!this.state.isCropped && this.state.instructor.img && <img
                                            className="img-fluid rounded-circle"
                                            src={constand.USER_IMAGE_PATH +
                                                this.state.instructor.img
                                            }
                                            onError={(e) => commonService.checkImageCrop(e, 'instructor-placeholder.png')}
                                        />}
                                        {this.state.isCropped && this.state.instructor.img && <img
                                            className="img-fluid rounded-circle"
                                            src={
                                                this.state.instructor.img
                                            }
                                            onError={(e) => commonService.checkImageCrop(e, 'instructor-placeholder.png')}
                                        />}

                                    </div>
                                    <a
                                        className="font-book font-16 upload-btn color-blue"
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            document.getElementById("post_image").click();
                                        }}
                                    >
                                        <u>Upload Headshot</u>
                                        {<input
                                            type="file"
                                            onChange={this.onImageChange}
                                            onClick={(event) => {
                                                event.target.value = null
                                            }}
                                            className="filetype"
                                            style={{ display: "none" }}
                                            accept="image/x-png,image/jpg,image/jpeg"
                                            id="post_image"
                                        />}
                                    </a>
                                </div>

                                <div
                                    className={
                                        "form-group" +
                                        (submitted && this.state.optionSelected.userid.length == 0 ? " has-error" : "")
                                    }
                                >
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="font-semibold black-txt"
                                    >
                                        Beam User <span className="orangefont">*</span>
                                    </label>
                                    <MultiSelectComponent
                                        className="select-container text-capitalize"
                                        placeholder="Select User"
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option,
                                            MultiValue,
                                            ValueContainer,
                                            animatedComponents
                                        }}
                                        //onInputChange={this.handleBeamUserChange}
                                        onChange={this.handleBeamUserChange}
                                        allowSelectAll={false}
                                        creatable={false}
                                        isAsync={true}
                                        value={this.state.optionSelected['userid']}
                                        name="userid"
                                        loadOptions={this.loadOptions}
                                        defaultOptions={this.state.userOptions}
                                    />
                                    {submitted && this.state.optionSelected.userid.length == 0 && (
                                        <div className="text-danger">Beam User is required</div>
                                    )}
                                </div>
                                {!this.props.logged_userData.isStudyLeader && (
                                    <div
                                    className={
                                        "form-group" +
                                        (submitted && (!this.state.optionSelected['condition'] || this.state.optionSelected['condition'].length == 0) ? " has-error" : "")
                                    }
                                    >
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-semibold black-txt"
                                        >
                                            Condition(s) <span className="orangefont">*</span>
                                        </label>
                                        <MultiSelectComponent
                                            className="select-container text-capitalize"
                                            options={this.state.conditionOptions}
                                            isMulti
                                            placeholder="Select Condition"
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option,
                                                MultiValue,
                                                ValueContainer,
                                                animatedComponents
                                            }}
                                            onChange={this.handleMultiSelectChange}
                                            onCreateOption={(value) => this.handleCreate(value, 'condition')}
                                            allowSelectAll={true}
                                            creatable={false}
                                            value={this.state.optionSelected['condition']}
                                            name="condition"
                                            removeTag={this.removeTag}
                                            isRemoveable={true}
                                        />
                                        {submitted && this.state.optionSelected.condition.length == 0 && (
                                            <div className="text-danger">Condition is required</div>
                                        )}
                                    </div>
                                )}                                
                                {!this.props.logged_userData.isStudyLeader && (
                                    <div className="col-md-12 text-center p-0 m-b-15">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="m-t-5 pull-left" htmlFor="hasProfile">
                                                    <input type="checkbox" name="hasProfile" id="hasProfile" checked={this.state.instructor.hasProfile} onChange={this.handleCheckboxChange} value={this.state.instructor.hasProfile} />
                                                </label>
                                                <span className="font-semibold black-txt">Full Instructor Profile</span>

                                            </div>
                                        </div>
                                    </div>
                                )}                                
                                {this.state.instructor.hasProfile && !this.props.logged_userData.isStudyLeader &&
                                    <div>
                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Title
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="title"
                                                value={this.state.instructor.title}
                                                onChange={this.handleChange}
                                                maxLength={constand.Input_Text_Limit}
                                            />
                                            {this.state.instructor.title ? (
                                                <div className="pull-right font-13 twitter-color mt-1">
                                                    Characters Left: { constand.Input_Text_Limit - this.state.instructor.title.length}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Health Area of Expertise
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="speciality"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="speciality"
                                                value={this.state.instructor.speciality}
                                                onChange={this.handleChange}
                                                maxLength={constand.Input_Text_Limit}
                                            />
                                            {this.state.instructor.speciality ? (
                                                <div className="pull-right font-13 twitter-color mt-1">
                                                    Characters Left: { constand.Input_Text_Limit - this.state.instructor.speciality.length}
                                                </div>
                                            ) : null}

                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Movement Area of Expertise
                                            </label>
                                            <MultiSelectComponent
                                                className="select-container text-capitalize"
                                                options={this.state.disciplineOptions}
                                                isMulti
                                                placeholder="Select"
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option,
                                                    MultiValue,
                                                    ValueContainer,
                                                    animatedComponents
                                                }}
                                                onChange={this.handleMultiSelectChange}
                                                onCreateOption={(value) => this.handleCreate(value, 'discipline')}
                                                allowSelectAll={false}
                                                creatable={true}
                                                value={this.state.optionSelected['discipline']}
                                                name='discipline'
                                                removeTag={this.removeTag}
                                                isRemoveable={true}
                                            />

                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Qualification
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="qualifications"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="qualifications"
                                                value={this.state.instructor.qualifications}
                                                onChange={this.handleChange}
                                                maxLength={constand.Input_Text_Limit}
                                            />
                                            {this.state.instructor.qualifications ? (
                                                <div className="pull-right font-13 twitter-color mt-1">
                                                    Characters Left: { constand.Input_Text_Limit - this.state.instructor.qualifications.length}
                                                </div>
                                            ) : null}

                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Feel Good Formula
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="feelGoodFormula"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="feelGoodFormula"
                                                value={this.state.instructor.feelGoodFormula}
                                                onChange={this.handleChange}
                                                maxLength={constand.Input_Text_Limit}
                                            />
                                            {this.state.instructor.feelGoodFormula ? (
                                                <div className="pull-right font-13 twitter-color mt-1">
                                                    Characters Left: { constand.Input_Text_Limit - this.state.instructor.feelGoodFormula.length}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Region
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="location"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="location"
                                                value={this.state.instructor.location}
                                                onChange={this.handleChange}
                                                maxLength={constand.Input_Text_Limit}
                                            />
                                            {this.state.instructor.location ? (
                                                <div className="pull-right font-13 twitter-color mt-1">
                                                    Characters Left: { constand.Input_Text_Limit - this.state.instructor.location.length}
                                                </div>
                                            ) : null}
                                        </div>


                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                About
                                            </label>
                                            {/* <RichTextEditor
                                                className="richText font-qregular"
                                                toolbarConfig={toolbarConfig}
                                                value={this.state.editorValue}
                                                onChange={this.handleChangeTextEdit}
                                            /> */}
                                            <CKEditor
                                                initData={this.state.about}
                                                config={{
                                                    versionCheck:false,
                                                    toolbar: [
                                                        ['Bold', 'Italic'],
                                                        ['NumberedList', 'BulletedList'],
                                                        ['Link', 'Unlink'],
                                                        ['Image', 'Table']
                                                    ],
                                                    height: '300px',
                                                    resize_enabled: false,
                                                    font_defaultLabel: 'Qregular',
                                                    fontSize_defaultLabel: '28px',
                                                    //addCss: ('body {font-size: 10px}'),
                                                    contentsCss: '../../../ckeditor.css',
                                                    stylesSet: [
                                                        {
                                                            element: 'p',
                                                            styles: { 'font-family': '#(Qregular)' },
                                                            overrides: [{ element: 'font', attributes: { 'face': null } }]
                                                        }
                                                    ],
                                                    font_style:
                                                        [{
                                                            element: 'p',
                                                            styles: { 'font-family': '#(Qregular)' },
                                                            overrides: [{ element: 'font', attributes: { 'face': null } }]
                                                        }
                                                        ],
                                                    font_names: 'Qregular'
                                                }}
                                                onChange={(value) => { this.handleChangeTextEdit(value) }}
                                                onBeforeLoad={(CKEDITOR) => CKEDITOR.on('dialogDefinition', ev => {
                                                    if (ev.data.name == 'link') {
                                                        ev.data.definition.getContents('target').get('linkTargetType')['default'] = '_blank';
                                                        ev.data.definition.getContents('info').get('protocol')['default'] = 'https://';
                                                    }
                                                })}
                                            />

                                            {/* this.state.instructor.about ? (
                                                <div class="pull-right font-13 twitter-color">
                                                    Characters Left:{this.state.instructor.about.length}-{ 50 - this.state.instructor.about.length}
                                                </div>
                                            ) : null */}

                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Twitter URL
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="twitterLink"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="twitterLink"
                                                value={this.state.instructor.twitterLink}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && this.state.twitterLinkError &&
                                                <div className="text-danger">Url is not valid</div>
                                            }
                                        </div>
                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Instagram URL
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="instagramLink"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="instagramLink"
                                                value={this.state.instructor.instagramLink}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && this.state.instagramLinkError &&
                                                <div className="text-danger">Url is not valid</div>
                                            }
                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Facebook URL
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="facebookLink"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="facebookLink"
                                                value={this.state.instructor.facebookLink}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && this.state.facebookLinkError &&
                                                <div className="text-danger">Url is not valid</div>
                                            }
                                        </div>

                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                LinkedIn URL
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="linkedinLink"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="linkedinLink"
                                                value={this.state.instructor.linkedinLink}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && this.state.linkedinLinkError &&
                                                <div className="text-danger">Url is not valid</div>
                                            }
                                        </div>
                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                TikTok URL
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="tiktokLink"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="tiktokLink"
                                                value={this.state.instructor.tiktokLink}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && this.state.tiktokLinkError &&
                                                <div className="text-danger">Url is not valid</div>
                                            }
                                        </div>
                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-semibold black-txt"
                                            >
                                                Website
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="webLink"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="webLink"
                                                value={this.state.instructor.webLink}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && this.state.webLinkError &&
                                                <div className="text-danger">Url is not valid</div>
                                            }
                                        </div>
                                    </div>}
                                <div className="col-md-12 text-center p-0 m-b-15">
                                    <div className="row">
                                        <div className="col-md-4">
                                            {this.state.instructor.hasProfile && !this.props.logged_userData.isStudyLeader &&
                                                <div>
                                                    <label className="m-t-5 pull-left" htmlFor="isLive">
                                                        <input type="checkbox" name="isLive" id="isLive" checked={this.state.instructor.isLive} onChange={this.handleCheckboxChange} value={this.state.instructor.isLive} />
                                                    </label>
                                                    <span className="font-semibold black-txt">List this Instructor Profile</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="offset-4 col-md-4">
                                            <button
                                                disabled={loading}
                                                onClick={this.onSubmit}
                                                className=" btn btn-block mybtn btn-login tx-tfm font-book font-14"
                                            >Save Changes </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        is_model_open: state.header.is_loginModelOpen,
        init_condition: state.auth.initial_condition,
        all_condition: state.register.healthcondition_list,
        is_cms_model: state.workout.is_cms_model,
        cms_model_type: state.workout.cms_model_type,
        instructor_detail: state.instructor.instructor_detail,
        cropped_image: state.workout.cropped_image,
        cropped_file: state.workout.cropped_file,
        logged_userData: state.header.logged_userData
    };
};

const mapDispatchToProps = {
    getInstTags,
    getBeamUser,
    ondemandCMSModelOpen,
    createNewTag,
    removeTag,
    clearInstructorList,
    imageCropOpenPopup,
    getUserForStudyInstructor
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstructorCMSComponent);
