import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { commonService } from "../../_services";
import { getInstructorsList, start_loader, stop_loader, ondemandCMSModelOpen, getInstructorDetails, clearInstructorDetail } from "../../actions";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import { ImageTag } from "../../tags";
import { Helmet } from "react-helmet";
import InstructorCMSComponent from "./InstructorCMSComponent";
import AdminBanner from "../Common/AdminBanner";

class InstructorsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      instructorList: [],
      typeFilter: this.props.params.condition,
      show_more_cnt: constand.INSTRUCTOR_LIST_CNT,
      sort_filter: "",
      search_data: "",
      offset: 0,
      list_count: 0,
      is_filter_open: false,
      available_filter: {},
      selected_filter: {},
      filterNames: ["discipline"]
    };
    this.reCallInstList = this.reCallInstList.bind(this);
    this.getInstructorList = this.getInstructorList.bind(this);
    this.incrementShowmoreCnt = this.incrementShowmoreCnt.bind(this);
    this.sortByFunction = this.sortByFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchFucntion = this.searchFucntion.bind(this);
    this.filterFunction = this.filterFunction.bind(this);
    this.lastState = this.props.params.condition;
  }
  componentDidMount() {
    this.props.ondemandCMSModelOpen({ open: false })
    this.getInstructorList();
  }
  componentWillUnmount() {
    this.setState({
      Loading: false,
      instructorList: [],
      typeFilter: this.props.params.condition,
      show_more_cnt: constand.INSTRUCTOR_LIST_CNT,
      sort_filter: "",
      search_data: "",
      offset: 0,
      list_count: 0,
      is_filter_open: false,
      available_filter: {},
      selected_filter: {}
    });
  }
  componentWillReceiveProps(props) {
    console.log('componentWillReceiveProps')
    if (this.lastState != props.params.condition) {
      this.lastState = props.params.condition;
      this.setState(
        {
          typeFilter: props.params.condition
        },
        function () {
          this.reCallInstList();
        }
      );
    }
  }
  getInstructorDetails(id) {
    this.props.getInstructorDetails(id).then(
      response => {
        this.props.ondemandCMSModelOpen({ open: true, type: 'edit' })
      }, error => {
        console.log('err', error)
      });
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  sortByFunction(item) {
    this.setState(
      {
        sort_filter: item,
        instructorList: [],
        offset: 0
      },
      function () {
        this.getInstructorList();
      }
    );
  }
  searchFucntion() {
    this.setState({ instructorList: [], offset: 0 }, function () {
      this.getInstructorList();
    });
  }
  reCallInstList() {

    this.setState({ instructorList: [], offset: 0 }, function () {
      this.getInstructorList();
    });
  }
  incrementShowmoreCnt() {
    this.setState(
      {
        offset: this.state.offset + constand.INSTRUCTOR_LIST_CNT
      },
      function () {
        this.getInstructorList();
      }
    );
  }
  filterFunction(name, value) {
    var temp = { ...this.state.selected_filter };
    if (!temp[name]) {
      temp[name] = [];
    }
    var index = temp[name].indexOf(value);
    if (index > -1) {
      temp[name].splice(index, 1);
    } else {
      temp[name].push(value);
    }
    this.setState({ instructorList: [], selected_filter: temp, offset: 0 }, function () {
      this.getInstructorList();
    });
  }
  checkFilterEmpty(dataObj) {
    var return_obj = dataObj;
    if (Object.keys(dataObj).length > 0 && dataObj.constructor === Object) {
      if (dataObj.discipline.length <= 0) {
        return_obj = {};
      }
    }
    return return_obj;
  }
  getInstructorList() {
    this.setState({ Loading: true }, function () {
      var dataObj = {
        offset: this.state.offset,
        limit: constand.INSTRUCTOR_LIST_CNT,
        sortby: this.state.sort_filter,
        keywords: this.state.search_data,
        filters: this.checkFilterEmpty(this.state.selected_filter)
      };
      var typeFilter = commonService.replaceChar(this.state.typeFilter, true);
      //this.props.start_loader();
      this.props.getInstructorsList(dataObj, typeFilter).then(
        response => {
          if (response) {
            var prev_data = this.state.instructorList;
            var new_list = prev_data.concat(this.props.instructor_list);
            this.setState({
              Loading: false,
              instructorList: new_list,
              list_count: response.count,
              available_filter: (response.list.availableFilters) ? response.list.availableFilters : this.state.available_filter,
              selected_filter: (response.list.selectedFilters) ? response.list.selectedFilters : this.state.selected_filter
            });
          }
          //this.props.stop_loader();
        },
        error => {
          this.setState({
            Loading: false
          });
          //this.props.stop_loader();
          toast.error(error);
        }
      )
    });
  }
  /**
   * on enter search
   * @param {*} e
   */
  searchBarEnter(e) {
    if (e.key === 'Enter') {
      this.searchFucntion()
    }
  }
  /**
  * To display selected item
  */
  renderSelectedFilter() {
    let value = [];
    this.state.filterNames.map((item, key) => {
      if (this.state.selected_filter && this.state.selected_filter[item] && this.state.selected_filter[item].length > 0) {
        this.state.selected_filter[item].map((val, index) => (
          value.push(
            <div key={"filter_rm_" + index + key} onClick={() => {
              this.removeFilterItem(item, val)
            }}>
              <span className="pointer"> x  </span>
              <span className="capitalize_text pointer">
                {val}
              </span>

            </div>
          )
        ));
      }
    })
    return value;
  }
  /**
   * to remove selected filter item
   * @param {*} item
   * @param {*} val
   */
  removeFilterItem(item, val) {
    this.filterFunction(item, val);
  }
  render() {
    let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

    return (
      <div className="">
        <Helmet>
          <title>{constand.INSTRUCTORS_TITLE}{this.props.params.condition}{constand.BEAM}</title>
          <meta property="og:title" content={constand.INSTRUCTORS_TITLE + this.props.params.condition + constand.BEAM} />
          <meta property="og:description" content={constand.INSTRUCTORS_DESC} />
          <meta property="og:image" content={constand.INSTRUCTORS_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.INSTRUCTORS_PAGE_IMAGE_ALT} />
        </Helmet>
        <AdminBanner condition={this.props.params.condition} />
        <div className="container-fluid mx-auto w-95 p-0">
          {/*}<div className="text-center p-t-50 p-b-40">
              <h3 className="font-book font-24 darkblue-text">
                Lorem Ipsum dolor sit amet
              </h3>
              <p className="w-50 w-xs-100 mx-auto p-t-30 p-b-10 font-14 font-qregular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                dictum risus at imperdiet  egestas. Aliquam rutrum, fells
                sed accumsan tristique, sapien nulla blandit odio, quis {" "}
                pretium tortor lectus id lacus. Mauris sit amet nunc cursus,
                pretium elit a, ultricies est.
                Fusce urna elit, tincidunt dignissim arcu tempor,
                scelerisque tincidunt mi. Sed in ultrices
                sapien. Integer congue nisi nec mollis egestas. Donec eget ipsum
                nibh.
              </p>
            </div>*/}
          <div className="filter-section col-md-12 m-t-10 m-b-10">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                {/* <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-link black-txt sort-btn font-book  font-14 dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Sort by{" "}
                      <i
                        className="fa fa-angle-down p-l-5"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div className="dropdown-menu">
                      {ONDEMAND_SORT_FILTERLIST.map((item, key) => {
                        return (
                          <span
                            key={key}
                            className="dropdown-item pointer"
                            onClick={() => this.sortByFunction(item)}
                          >
                            {item}
                          </span>
                        );
                      })}
                    </div>
                  </div> */}
              </div>
              <div className="col-md-8 col-sm-8 col-sm-12 col-12 m-t-20">
                <div className="input-group mx-auto w-75 search-btn">
                  <input
                    type="text"
                    className="form-control font-14 font-qregular"
                    placeholder="Which instructor are you looking for?"
                    name="search_data"
                    value={this.state.search_data}
                    onChange={this.handleChange}
                    onKeyUp={this.searchBarEnter.bind(this)}
                  />
                  <div className="input-group-append">
                    <button disabled={this.state.Loading}
                      className="btn btn-secondary font-medium font-14"
                      type="button"
                      onClick={this.searchFucntion}
                    >
                      Search
                      </button>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 text-right d-none">
                {(!this.state.is_filter_open) &&
                  <span className="btn btn-purple-inverse filter-btn font-book font-14  pointer" onClick={() => this.setState({ is_filter_open: true })}>
                    Filter
                        </span>}
                {(this.state.is_filter_open) &&
                  <span className="btn btn-purple close-btn font-book font-14 pointer" onClick={() => this.setState({ is_filter_open: false })}>Close</span>
                }
              </div>
              {this.state.selected_filter && this.state.filterNames && !this.state.is_filter_open && (
                <div className="font-14 orangefont filter-select w-100 m-t-20 font-book text-center">
                  {this.renderSelectedFilter()}
                </div>
              )}
              {this.state.is_filter_open && (
                <div className="col-md-12 filter_grid m-t-50 ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4 p-l-0 m-b-10">
                          <div className="filter_head  black-txt small-txt">
                            Discipline
                            </div>
                          {this.state.available_filter.discipline.map((item, key) => {
                            return (
                              <span key={"discipline_" + key}
                                className="font-medium black-txt extra-small-txt pointer clearfix" onClick={() => this.filterFunction('discipline', item)}
                              >
                                <p className={((this.state.selected_filter.discipline) && (this.state.selected_filter.discipline).includes(item)) ? "orangefont" : ""}>{item}</p>
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-12 m-b-20">
            <div className="row">
              {this.props.is_auth && this.props.is_create_mode && !authData.isStudyLeader &&
                <div
                  className="col-md-6 col-lg-4 p-l-0 m-b-30"
                >
                  <div class="instruct-card-design"><div class="col-md-12">
                    <div class="row">
                      <div class="instructor-photo col-md-3 p-0">
                        <img class="rounded-circle img-fluid" src={constand.WEB_IMAGES + 'instructor-placeholder.png'} />

                      </div>
                      <div class="instructor-content col-md-9 p-r-0">
                        <div class="blog-top clearfix">
                          <p class="font-semibold  font-18 m-b-0">New Beam Instructor</p>

                          <p class="font-qmedium border-bottom silver-txt font-16 m-b-0">Title</p>
                          <div class="condition_status">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="row">
                      <div class="col-md-12 instruct_details">
                        <div className="col-12 float-left m-b-10 p-0 font-medium">
                          <p className="float-left font-14 black-txt font-qregular m-t-20">
                            Click the plus sign to create a new Beam Instructor profile! You will need...
                              </p>

                        </div>
                        <div className="col-11 float-left p-0">
                          <ul className="black-txt col-md-12 col-sm-12 display-grid">
                            <li><span className="font-14 font-qregular float-left">
                              Beam Class Instructor Profile Google Form Response
                            </span></li>
                            <li><span className="font-14 font-qregular float-left">
                              Instructor Headshot
                            </span></li>
                          </ul>
                        </div>
                        {this.props.is_auth && this.props.is_create_mode &&
                          <div className="col-1 float-left p-0 pl-2 top-30 pointer" onClick={() => { this.props.ondemandCMSModelOpen({ open: true, type: 'add' }); this.props.clearInstructorDetail(); }}>
                            <img src={constand.WEB_IMAGES + "add-plus.png"} />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
              {this.state.instructorList.map(item => {
                return (
                  <div
                    className="col-md-6 col-lg-4 p-l-0 m-b-30"
                    key={item.id}
                  >
                    <div className="instruct-card-design">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="instructor-photo col-md-3 p-0">
                            {item.hasProfile &&
                              <Link
                                to={"/instructor/" + item.id + '/' + this.props.params.condition}
                              >
                                <ImageTag
                                  className="rounded-circle img-fluid"
                                  src={constand.USER_IMAGE_PATH + item.img}
                                  thumb={constand.WEB_IMAGES + "instructor-placeholder.png"}

                                />
                              </Link>
                            }
                            {!item.hasProfile &&
                              <ImageTag
                                className="rounded-circle img-fluid"
                                src={
                                  item.img
                                    ? constand.USER_IMAGE_PATH +
                                    item.img
                                    : constand.WEB_IMAGES + "no-image.png"
                                }
                              />
                            }
                          </div>
                          <div className="instructor-content col-md-9 p-r-0">
                            <div className="blog-top clearfix">
                              {item.hasProfile &&
                                <Link
                                  to={"/instructor/" + item.id + '/' + this.props.params.condition}
                                >
                                  <p className="font-semibold  font-18 m-b-0">
                                    {item.User
                                      ? item.User.name + " " + item.User.lastName
                                      : ""}
                                  </p>
                                </Link>
                              }
                              {!item.hasProfile &&
                                <p className="font-semibold  font-18 m-b-0">
                                  {item.User
                                    ? item.User.name + " " + item.User.lastName
                                    : ""}
                                </p>
                              }
                              <p className="font-qmedium border-bottom silver-txt font-16 m-b-0">
                                {item.title ? item.title : ''}
                                {/* commonService.returnLabelData('conditionConnection', (item.InstructorTags) ? item.InstructorTags : []) */}
                              </p>
                              {/* <div className="condition_status">
                                  <span className="font-qregular font-13">
                                    Connection to condition:
                                  </span>
                                  <span className="font-qregular  font-13 ">
                                  {commonService.returnLabelData('conditionConnection', (item.InstructorTags) ? item.InstructorTags : [])}
                                  </span>
                                </div> */}
                              <div className="condition_status">
                                {/* <span className="font-qmedium  font-13 " dangerouslySetInnerHTML={{ __html: item.speciality.length > 75 ? item.speciality.substring(0,75) + "..." : item.speciality  }}>
                                  </span>
                                  <span className="font-qmedium  font-13 " dangerouslySetInnerHTML={{ __html: (commonService.returnUnderLineLabelData('discpline' ,item.InstructorTags)).length > 75 ? (commonService.returnUnderLineLabelData('discpline' ,item.InstructorTags)).substring(0,75) + "..." : (commonService.returnUnderLineLabelData('discpline' ,item.InstructorTags))  }}></span>
                                  */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 instruct_details">
                        <div className="row">
                          <p
                            className="list font-qregular  font-14 m-t-20"
                            dangerouslySetInnerHTML={{ __html: item.bio.length > constand.INSTRUCTOR_WORD_LIMIT ? item.bio.substring(0, constand.INSTRUCTOR_WORD_LIMIT) + "..." : item.bio }}
                          ></p>
                          {!this.props.logged_userData.isStudyLeader && !this.props.logged_userData.isGroupLeader && !!this.props.logged_userData.isStudyInstructor &&
                            <div className="col-11 float-left p-0">
                              {item.hasProfile &&
                                <Link
                                  to={"/instructor/" + item.id + '/' + this.props.params.condition}
                                  className="orangefont read-btn font-qregular font-14"
                                >
                                  <u>Read more</u>
                                </Link>
                              }
                            </div>
                          }

                        </div>

                      </div>
                      {this.props.is_auth && this.props.is_create_mode &&
                        <div className="col-12 p-0 pl-2 pointer p-b-10" onClick={() => { this.getInstructorDetails(item.id) }}><img src={constand.WEB_IMAGES + "edit-pencil.png"} className="pull-right" /></div>
                      }
                    </div>
                  </div>
                );
              })}
              {(this.state.instructorList.length === 0) && <div className="text-center w-100">
                {(!this.state.Loading) ? ("There is no Instructors") : (<i className="fa fa-spinner fa-spin fa-2x text-info" aria-hidden="true"></i>)}
              </div>}
              {(this.state.instructorList.length > 0) &&
                <div className="col-md-12 col-lg-12 col-sm-12 text-center clearfix m-t-40 m-b-40">
                  {(this.state.instructorList.length < this.state.list_count) &&
                    <button disabled={(this.state.instructorList.length >= this.state.list_count)}
                      className="button-lightblue font-14 font-book"
                      onClick={this.incrementShowmoreCnt}
                    >
                      Show more instructors
                  </button>}
                </div>}
              {this.props.is_cms_model &&
                <InstructorCMSComponent getList={this.reCallInstList} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
    instructor_list: state.instructor.instructor_list,
    is_create_mode: state.header.is_create_mode,
    is_cms_model: state.workout.is_cms_model,
    logged_userData: state.header.logged_userData
  };
};

const mapDispatchToProps = {
  getInstructorsList, start_loader, stop_loader, ondemandCMSModelOpen, getInstructorDetails, clearInstructorDetail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstructorsComponent);
