import React from 'react';
import { Link } from 'react-router-dom';
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { commonService } from "../../_services";

class LiveclassComponent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         user: {},
         users: []
      };
      this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
      this.returnClass = this.conditonClassList.reverse();

   }
   componentWillReceiveProps() {
      this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
   }
   componentDidMount() {
      window.scrollTo(0, 0)
   }
   getColorClass(key) {
      if (this.returnClass.length > 0)
         return this.returnClass[key];
   }
   render() {
      const { user, users } = this.state;
      return (
         <div className="liveclassescontent m-t-80">
            <Helmet>
               <title>{constand.ABOUT_LIVE_TITLE}{constand.BEAM}</title>
               <meta property="og:title" content={constand.ABOUT_LIVE_TITLE + constand.BEAM} />
               <meta property="og:description" content={constand.ABOUT_LIVE_DESC} />
               <meta property="og:image" content={constand.ABOUT_LIVE_PAGE_IMAGE} />
               <meta property="og:url" content={window.location.href} />
               <meta name="twitter:card" content="summary_large_image" />
               <meta property="og:site_name" content="Beam" />
               <meta name="twitter:image:alt" content={constand.ABOUT_LIVE_PAGE_IMAGE_ALT} />
            </Helmet>
            <section id="livesection1" className="livesection1-bg">
               <div className="container">
                  <div className="section-header text-center p-t-40 p-b-40">
                     <h3 className="orangefont position-relative font-37 font-medium">Live Classes on Beam
                        {/* <span className="position-absolute font-semibold font-20 purplefont floting-text">NEW!</span> */}
                     </h3>
                     <p className="w-75 mx-auto p-t-20 p-b-100 black-txt font-21 font-normal font-medium">Come and experience the feel-good energy of a live class <br /> from the comfort of your own home!.</p>
                  </div>
               </div>
            </section>
            <section id="livesection2" className="livesection2-bg">
               <div className="container-fluid mx-auto w-75">
                  <div className="section-header text-center p-t-50 p-b-50 d-none d-sm-none d-md-block d-lg-block">
                     {/* <p className=" mx-auto black-txt font-qregular  font-16 m-b-20 ">Live classes allow you to experience the feel-good energy of a live class wherever you are.</p> */}
                     <p className=" mx-auto black-txt font-qregular font-16 m-b-20">
                     Just like our on-demand sessions, live classes are run by specialist instructors who are either living with or working in your health condition. We want you to know that the Beam Team understands you and has your back, every minute of every workout.
                     </p>
                     <p className="mx-auto black-txt font-qregular font-16 m-b-20">Get live encouragement during the session, connect with your instructor and classmates pre and post-session, and feel supported at every step by people who deeply understand your needs.</p>
                  </div>
               </div>
            </section>
            <section id="livesection3" className="livesection3-bg p-t-40 p-b-40 h-auto">
               <div className="container-fluid mx-auto w-75 m-t-30">
                  <div className="row">
                     <div className="col-12 col-md-6 offset-lg-1 col-lg-6 text-left ">
                        <h4 className="m-b-20 darkblue-text font-24 font-semibold">How do I sign up for a live class?</h4>
                        <p className="black-txt font-16 font-qregular">Browse our schedule of live classes and click 'sign-up’ to register for a class that appeals to you. You can see the classes that you've signed up for on your personal dashboard by clicking on ‘my beam’ in the upper-righthand corner.</p>
                        {/* this.props.healthcondition_list.length > 0 &&
                           this.props.healthcondition_list.map((item, key) => {
                              return (
                                 <div className="row col-md-12 col-lg-10 col-sm-12">
                                    <Link key={key} className={"btn m-b-20 font-16 font-medium "+ this.getColorClass(key)} to={"/liveClasses/"+item.tag}>{commonService.toCapitalize(item.tag)} live class schedule</Link>
                                    
                                 </div>
                              )
                           }) */}
                        <div className="row col-md-12 col-lg-10 col-sm-12">
                           <Link className="btn btn-yellow m-t-20 m-b-20 font-16 font-medium" to="/liveClasses/cystic fibrosis">CF live class schedule</Link>
                        </div>
                        {/* <div className="row col-md-12 col-lg-10 col-sm-12">
                           <Link className="btn btn-orange m-t-0 m-b-20 font-16 font-medium" to="/liveClasses/Postnatal">Postnatal live class schedule</Link>
                        </div>
                        <div className="row col-md-12 col-lg-10 col-sm-12">
                           <Link className="btn btn-purple m-t-0 m-b-20 font-16 font-medium" to="/liveClasses/Menopause">Menopause live class schedule</Link>
                        </div> */}
                        {/* <div className="row col-md-12 col-lg-10 col-sm-12">
                           <Link className="btn btn-blue m-t-0 m-b-20 font-16 font-medium" to="/liveClasses/kidney disease">Kidney live class schedule</Link>
                        </div> */}
                        {/* <div className="row col-md-12 col-lg-10 col-sm-12">
                           <Link className="btn btn-darkblue m-t-0 m-b-20 font-16 font-medium" to="/liveClasse/prostate cancer">Prostate cancer live class schedule</Link>
                        </div> */}
                        <div className="row col-md-12 col-lg-10 col-sm-12">
                           <Link className="btn btn-orange m-t-0 m-b-20 font-16 font-medium" to="/liveClasse/cystic fibrosis youth">CF youth live class schedule</Link>
                        </div>
                     </div>
                     <div className="col-12 col-md-6 col-lg-5 text-center">
                        <figure><img src={constand.WEB_IMAGES + "Floor_mat_pair_2_RGB.png"} className="img-fluid" alt="" /></figure>
                     </div>
                  </div>
               </div>
            </section>
            <section id="livesection4" className="livesection4-bg p-t-40 p-b-40">
               <div className="container-fluid mx-auto w-75">
                  <h4 className="text-center font-semibold font-normal font-24 purplefont m-b-20">How should I prepare for my live class?
                  </h4>
                  <div className="row">
                     <div className="col-12 col-md-6 col-lg-6 text-center">
                        <figure className="text-center"><img src={constand.WEB_IMAGES + "live1.png"} className="img-fluid" alt="" /></figure>
                     </div>
                     <div className="col-12 col-md-6 col-lg-6 text-left m-t-80">
                        <h4 className="m-b-20 font-semibold font-normal font-24 orangefont">Step 1: The Tech</h4>
                        <p className="font-16 black-txt font-qregular w-86">Our live exercise classes are run on Zoom, a video conferencing tool we know and love. Before you take part in your first live exercise class we suggest you create an account on Zoom - it's completely free. Download their desktop or mobile app, then test out your audio and video as instructed to make sure you're ready to go at the time of your first class.
                        </p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12 col-md-5 offset-md-1 orders-last col-lg-5 text-left  offset-lg-1 m-t-80">
                        <h4 className="m-b-20 font-semibold font-normal font-24 purplefont">Step 2: Let us know if you have any questions</h4>
                        <p className="font-16 black-txt font-qregular">After you've signed up for a class you'll receive an email from us with all the relevant info. This email includes how to get hold of us if you have any questions before class.
                        </p>
                     </div>
                     <div className="col-12 col-md-6 col-lg-6 text-center">
                        <figure className="text-center col-md-6 mx-auto"><img src={constand.WEB_IMAGES + "live2.png"} className="img-fluid" alt="" /></figure>
                     </div>
                  </div>
                  <div className="row">

                     <div className="col-12 col-md-6 col-lg-6 text-center">
                        <figure className="text-center"><img src={constand.WEB_IMAGES + "live3.png"} className="img-fluid" alt="" /></figure>
                     </div>
                     <div className="col-12 col-md-6 col-lg-6 text-left m-t-80">
                        <h4 className="m-b-20 font-semibold font-normal font-24 darkblue-text">Step 3: Get yourself ready</h4>
                        <p className="font-16 black-txt font-qregular w-86">Shortly before the class, change into some comfortable clothes, drink some water, fill up your bottle for the class and pick a good spot to do the class.
                           A list of equipment needed for the class can be found in the sign-up email. This should help you to pull everything you need together before class so you can enjoy the session!
                        </p>
                     </div>
                  </div>
               </div>
            </section>
            <section id="livesection5" className="livesection5-bg live-class-height-bg">
               <div className="container">
                  <div className="section-header text-center p-t-60 p-b-30">
                     <h4 className="purplefont font-24 font-normal font-semibold">How do I join a live class?</h4>
                     <p className="w-50 mx-auto p-t-40  font-16 black-txt font-qregular">At class time click the "go to class" link from either the live class timetable on Beam, your Beam dashboard, or any of our reminder emails.
                     </p>
                     <p className="w-50 mx-auto p-t-5  font-16 black-txt font-qregular">The link will direct you to Zoom.</p>
                     <p className="w-50 mx-auto p-t-5 font-16 black-txt font-qregular">Test the computer audio and camera before entering the session to make sure you can hear and be heard.</p>
                     <p className="w-50 mx-auto p-t-5 font-16 black-txt font-qregular">If the instructor (Zoom will call them the 'host') has not yet started the session you'll be asked to wait, otherwise, you can enter the virtual exercise studio immediately.</p>
                     <p className="w-50 mx-auto p-t-5 font-16 black-txt font-qregular">Once you’ve entered the session, you can change your screen name or use an alias and add your preferred pronouns, you can take part in the pre-class chat, soak up the good vibes, and get in a good mindset for the class that's about to start.</p>
                     <p className="w-50 mx-auto p-t-5 font-16 black-txt font-qregular">*Closed captioning will be available in all live classes</p>
                  </div>
               </div>
            </section>
            <section id="livesection6" className="livesection6-bg">
               <div className="container-fluid mx-auto w-75">
                  <div className="section-header text-center p-t-30 p-b-30">
                     <div className="row">
                        <div className="col-12 col-md-6 offset-lg-1 col-lg-6 text-left m-t-80">
                           <h4 className="m-b-20 font-semibold font-normal darkblue-text font-24">What do I do after class?</h4>
                           <p className="font-16 black-txt font-qregular w-80 m-b-0">Give yourself a massive pat on the back and be proud of yourself for showing up!<br />
                              After the class, you're welcome to stay and chat directly with the instructor or other people in the session.</p><p className="font-16 black-txt font-qregular w-86">
                              We may send you a quick questionnaire to fill out so we can get your feedback on <br />
                              how it went and improve it for you in the future.</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 text-center">
                           <figure className="text-center"><img src={constand.WEB_IMAGES + "live4.png"} className="img-fluid" alt="" /></figure>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section id="livesection5" className="livesection5-bg">
               <div className="container">
                  <div className="section-header text-center p-t-60 p-b-30">
                     <h4 className="purplefont font-24 font-normal font-semibold">What if I miss class or want to take it again?</h4>
                     <p className="w-50 mx-auto p-t-40  font-16 black-txt font-qregular">Each live class is recorded and will appear in our on-demand video library within 2 weeks.  Keep a lookout for our on-demand drops every 2 weeks to repeat a class or try one you had to miss!
                     </p>
                  </div>
               </div>
            </section>
            {/* <section id="livesection7" className="livesection7-bg">
               <div className="container">
                  <div className="section-header text-center p-t-30 p-b-80">
                     <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 text-center m-t-50">
                           <h4 className="m-b-70 font-semibold font-24 font-normal black-txt">Frequently Asked Questions</h4>
                           <div className="accordion mx-auto text-left" id="faqExample">
                              <div className="card">
                                 <div className="card-header p-2" id="headingOne">
                                    <h5 className="mb-0">
                                       <button className="btn btn-link collapsed faq-btn w-100 black-txt text-left position-relative font-24 font-normal font-semibold" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                          Will I be seen by other people in the virtual exercise studio?
                                          <img className="position-absolute image-fluid plus-sign" src="/images/plus1.png" alt="plus" />
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#faqExample">
                                    <div className="card-body font-16 font-medium">
                                       <b>Answer:</b> It works using the Bootstrap 4 collapse component with cards to make a vertical accordion that expands and collapses as questions are toggled.
                                    </div>
                                 </div>
                              </div>
                              <div className="card">
                                 <div className="card-header p-2" id="headingTwo">
                                    <h5 className="mb-0">
                                       <button className="btn btn-link collapsed faq-btn w-100 black-txt text-left position-relative font-semibold font-24 font-normal" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                          Can I be heard by other people in the virtual exercisestudio?
                                          <img className="position-absolute image-fluid plus-sign" src="/images/plus1.png" alt="plus" />
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#faqExample">
                                    <div className="card-body font-16 font-medium">
                                       Bootstrap is the most popular CSS framework in the world. The latest version released in 2018 is Bootstrap 4. Bootstrap can be used to quickly build responsive websites.
                                    </div>
                                 </div>
                              </div>
                              <div className="card">
                                 <div className="card-header p-2" id="headingThree">
                                    <h5 className="mb-0">
                                       <button className="btn btn-link collapsed faq-btn w-100 black-txt text-left position-relative font-semibold font-24 font-normal" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                          Will I be recorded?
                                          <img className="position-absolute image-fluid plus-sign" src="/images/plus1.png" alt="plus" />
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#faqExample">
                                    <div className="card-body font-16 font-medium">
                                       The answer to the question can go here.
                                    </div>
                                 </div>
                              </div>
                              <div className="card">
                                 <div className="card-header p-2" id="headingThree">
                                    <h5 className="mb-0">
                                       <button className="btn btn-link collapsed faq-btn w-100 black-txt text-left position-relative font-semibold font-24 font-normal" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                          Can I talk to the instructor during the class?
                                          <img className="position-absolute image-fluid plus-sign" src="/images/plus1.png" alt="plus" />
                                       </button>
                                    </h5>
                                 </div>
                                 <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#faqExample">
                                    <div className="card-body font-16 font-medium">
                                       The answer to this question can go here. This FAQ example can contain all the Q/A that is needed.
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section> */}
         </div>
      );
   }
}

//export { LiveclassComponent };

const mapStateToProps = state => {
   return {
      healthcondition_list: state.register.healthcondition_list
   };
};

const mapDispatchToProps = {};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(LiveclassComponent);
