import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    start_loader, getAWSImages
} from "../../actions";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import ReactPlayer from 'react-player';
import CommonForm from './CommonForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { commonService } from '../../_services';
import 'swiper/swiper-bundle.css';
import { Cookies } from "react-cookie-consent";

SwiperCore.use([Autoplay, Navigation]);
class CFLandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoUrl: 'https://youtu.be/fvc0D1ij2Rk',
            CFLogos: []
        }
        this.swiperRef = React.createRef();
    }

    componentDidMount() {
        this.getAWSImages();
    }

    getAWSImages = () => {
        var conditionName = Cookies.get('condition') ? Cookies.get('condition') : this.props.menu_condition;
        let data = { folderName: conditionName }
        this.props.getAWSImages(data).then((res) => {
            if (res && res.data) {
                let awsImgObjects = commonService.getAWSImages(res)
                this.setState({ CFLogos: awsImgObjects })
            }
        });
    }

    handleSlideChange = () => {
        if (this.swiperRef.current) {
            this.swiperRef.current.swiper.autoplay.start();
        }
    }

    render() {
        let userCurrency;
        if (this.props.user_ip_based_country_details && Object.keys(this.props.user_ip_based_country_details).length && this.props.user_ip_based_country_details.country) {
            userCurrency = this.props.user_ip_based_country_details.country.currency
        }
        let condition = commonService.replaceChar(this.props.params.condition, true);
        Cookies.set('condition', condition);

        return (
            <React.Fragment>
                <div className="kd_landing_page cancer_landing_page">
                    <section className="bg-contrast1">
                        <div class="container-fluid w-80 fullwidth">
                            <div class="row">
                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 text-center welcome-container mx-auto">
                                    <div className="welcome-kidney font-bold white-txt">Welcome to Beam Cystic Fibrosis</div>

                                    <div class="helping-physiotherap white-txt font-qbold col-11 mx-auto">Helping people living with cystic fibrosis to feel good through movement, education and wellbeing support.</div>
                                    <a className={(this.props.IP_Details.countryCode == 'US' ? "btn bluebtn m-t-50 font-18  p-t-10 p-b-10 w-60" : "btn bluebtn m-t-50 font-18  p-t-10 p-b-10 w-45")} href={this.props.IP_Details.countryCode == 'US' ? 'https://www.charityfootprints.com/portal/user_register?target_url=https%3A%2F%2Fwww.charityfootprints.com%2Fportal%2Fuser%2Fregistercampaign%2F1127' : '/register'}>{this.props.IP_Details.countryCode == 'US' ? 'Sign up to BreatheStrong+ to keep Beaming for free' : 'Start your two week free trial'} </a>
                                </div>
                                <div class="col-xl-3 col-lg-2 col-md-3 col-sm-3 d-sm-block header-img">
                                    <img class="img-fluid" src={constand.WEB_IMAGES + "Sun-landing.png"} />
                                </div>
                            </div>
                        </div>
                    </section>
                    {this.state.CFLogos && this.state.CFLogos.length ? <section>
                        <div class="container-fluid fullwidth">
                            <div class="col-lg-12">
                                <div class="partner-title-black" id="clinicians">Who we collaborate with</div>
                            </div>
                            <div class="row mt-3 mb-3">
                                <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0 m-b-50 slider-scroll-div">
                                    <Swiper
                                        centeredSlides={true}
                                        loop={true}
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                        }}
                                        containerModifierClass="w-90 "
                                        breakpoints={{ 1: { slidesPerView: 1, spaceBetweenSlides: 0 }, 1000: { slidesPerView: 5, spaceBetweenSlides: 50 } }}
                                        spaceBetween={10}
                                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                                        onSlideChange={this.handleSlideChange}
                                        ref={this.swiperRef}
                                        observeParents={true}
                                        observer={true}
                                        observeSlideChildren={true}
                                        watchSlidesProgress={true}
                                        className="mySwiper"
                                    >
                                        {this.state.CFLogos.length && this.state.CFLogos.map((slide, i) => (
                                            <>
                                                {slide &&
                                                    <SwiperSlide key={i}>
                                                        <img src={constand.S3_API_IMAGES + '/' + slide.Key} alt={`Slide ${i}`} className="scrolling-img" />
                                                    </SwiperSlide>}
                                            </>

                                        ))}
                                        <div className="swiper-buttons">
                                            <div className="swiper-button-prev"></div>
                                            <div className="swiper-button-next"></div>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </section> : null}
                    <section className="bg-contrast2">
                        <div class="container-fluid w-80 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">What we offer</div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="icon-desc m-t-20 font-qmedium offer-text" >
                                        Here at Beam we aim to create a space that will support and inspire you. We develop sessions designed to improve your physical and emotional wellbeing. Our instructors are specialists working in or living with CF so you can be confident they'll understand your needs.
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 icon-img">
                                    <div class="icon-img w-50 mx-auto condition-landing-img"><img src={constand.WEB_IMAGES + "On-demand.png"} /></div>

                                    <div class="icon-title icon-title-height" >On-demand video library</div>
                                    <Link class="btn btn-purple m-t-30 p-t-10 p-b-10" to={'on-demand/cystic-fibrosis'}>Explore</Link>
                                </div>
                                <div class="col-lg-4 col-md-4 icon-img">
                                    <div class="icon-img w-50 mx-auto condition-landing-img"><img src={constand.WEB_IMAGES + "Asset-60.png"} /></div>
                                    <div class="icon-title icon-title-height">Progressive Programmes</div>
                                    <Link class="btn btn-purple m-t-30 p-t-10 p-b-10" to={'programs/cystic-fibrosis'}>Explore</Link>
                                </div>

                                <div class="col-lg-4 col-md-4 icon-img">
                                    <div class="icon-img w-55 mx-auto condition-landing-img"><img src={constand.WEB_IMAGES + "Character-pair.png"} /></div>
                                    <div class="icon-title icon-title-height">Beam Clinics</div>
                                    <Link class="btn btn-purple m-t-30 p-t-10 p-b-10" to={'groups/cystic%20fibrosis'}>Explore</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="">
                        <div class="container-fluid w-80 p-b-20 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">How do I get started?</div>
                                </div>
                                {this.props.IP_Details.countryCode == 'US' ?
                                    <div class="section-content font-qmedium">
                                        Beam CF is teaming up with BreatheStrong+ (a programme of Miles4CF) to offer a comprehensive, virtual wellness programme available for FREE to all people (16+) with cystic fibrosis in the US through September 30, 2024! Free access to Beam classes are now available exclusively through the BreatheStrong+ programme thanks to the generous support of the Helen M. Eisenman Fund.  <a href="/blogs/beam-and-breathestrongplus-collaboration" className="section-content font-qmedium"><u>Read more here</u>.</a>
                                    </div>
                                    :
                                    <div class="section-content font-qmedium">
                                        Beam CF has a monthly subscription fee, however we want you to be sure you'll love it before you sign up so we offer a <span className="font-bold">2 week free trial.</span> Partnership with some CF charities and clinics also means that <span className="font-bold">Beam is free for some community members,</span> to find out if you're eligible for free access <a href="/blogs/beam-cf-am-i-eligible" className="purplefont"><u>please read this post</u>.</a>
                                    </div>
                                }
                            </div>
                            <div class="row">
                                <div className={this.props.IP_Details.countryCode == 'US' ? "col-lg-6 offset-lg-6 col-8 mx-auto text-center" : "col-lg-4 offset-lg-4 col-8 mx-auto text-center"}>
                                    <a class="btn bluebtn w-100 m-t-30 p-t-10 p-b-10" href={this.props.IP_Details.countryCode == 'US' ? 'https://www.charityfootprints.com/portal/user_register?target_url=https%3A%2F%2Fwww.charityfootprints.com%2Fportal%2Fuser%2Fregistercampaign%2F1127' : '/register'}> {this.props.IP_Details.countryCode == 'US' ? 'Sign up to BreatheStrong+ to keep Beaming for free' : 'Sign up'}</a>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="section-3 bg-contrast3 p-t-30 p-b-30">
                        <div className="container-fluid m-auto w-75 fullwidth">
                            <div className="row position-relative">
                                <div className="col-12 d-flex align-items-center  col-md-9 col-lg-8 text-left  p-b-30">
                                    <div className="card cardnew border-0 align-section bg-contrast3">
                                        <div className="card-block">
                                            <h4 className="clearfix text-abbey take-tour font-medium float-left w-100">Important safety information</h4>
                                        </div>
                                        <div className="m-t-30">
                                            <p className="before-start text-abbey ">
                                                Before you start exercising online we ask you to read these top recommendations to ensure that you keep safe and feel your best during and after you get physically active.
                                            </p>
                                        </div>
                                        <div className="readnow">
                                            <Link class="btn btn-darkblue col-md-5 col-10 p-t-10 p-b-10 w-80" to={'blogs/safety-info-cystic-fibrosis'}>Read Now</Link></div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-4 text-center align-self-center glass-image">
                                    <figure><img src={constand.WEB_IMAGES + "landing/Beam_Character_glass.png"} className="important-asset w-50" alt="" /></figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-white section-2 p-t-80">
                        <div className="container-fluid mx-auto w-75 fullwidth">
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-4 text-left p-b-30 video-player-order">
                                    <ReactPlayer url={this.state.videoUrl} controls={false} width='100%' height='100%' style={{
                                        width: '100%',
                                        height: '100%'
                                    }} />

                                    {/* <figure className="text-left"><img src="/images/blog_img_044.png" className="img-fluid" alt="" /></figure> */}
                                </div>

                                <div className="col-12 col-md-8 col-lg-8 pl-5 align-section align-items-center">
                                    <div className="">
                                        <h4 className="m-b-20 text-abbey take-tour font-semibold card-block">Take a tour</h4>

                                        <p className="text-abbey before-start">
                                            Beam co-founder, Pamela Scarborough talks you through the ins and outs of the website and all you need to know to get started in this short video.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section class="bg-contrast2">
                        <div class="container-fluid w-80 p-b-20 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">Frequently asked questions</div>
                                </div>
                                <div class="section-content font-qmedium w-100">
                                    If you still have questions you'll find a lot of them answered on our FAQ page.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 offset-lg-4 col-8 mx-auto text-center">
                                    <Link class="btn btn-orange w-100 m-t-30 p-t-10 p-b-10" to={'faq'}>FAQ</Link>
                                </div>

                            </div>
                        </div>
                    </section> */}
                    <section class="">
                        <div class="container-fluid w-80 p-b-20 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">For clinicians, exercise specialists, charities and partners</div>
                                </div>
                                <div class="section-content font-qmedium">
                                    Beam Cystic Fibrosis has been developed in collaboration with cystic fibrosis clinicians and charities. We strive to be a platform that you can trust and rely on and which will support you to care for your community. To learn more please head over to our <a href="/partnerships/cystic-fibrosis" className="purplefont"><u>partnerships page</u></a> - here you can also learn about opportunities to collaborate on research and content, and ways in which we can support you deliver remote care to your patients.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 offset-lg-4 col-8 mx-auto text-center">
                                    <Link class="btn bluebtn w-100 m-t-30 m-b-30 p-t-10 p-b-10" to={'partnerships/cystic-fibrosis'}>Partner with us</Link>
                                </div>
                            </div>
                            <div class="row">
                                <div class="section-content font-qmedium">
                                    If you are interested in becoming an instructor on Beam please <a href="https://forms.gle/Md6g5y7ufEetYvfM7" className="purplefont"><u>fill out this form</u>.</a> We look forward to hearing from you!
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="bg-contrast4">
                        <div class="container-fluid w-80 fullwidth" id="contact-form">
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <p class="font-medium take-tour text-abbey">Get in touch</p>
                                    <p class="font-qmedium text-abbey m-t-40 section-content">If you need help signing up, have any questions or want to get in touch for any other reason then please complete the form below. Not a fan of forms? Drop us a note to <a href='mailto:hello@beamfeelgood'> hello@beamfeelgood.com</a> and someone from the Beam Team will get back to you.</p>
                                    <div class="section-content text-left">
                                        <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                                            <CommonForm location={this.props.location} from="cf" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        )
    };
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        user_ip_based_country_details: state.register.user_ip_based_country_details,
        menu_condition: state.auth.menu_condition,
        IP_Details: state.accountinfo.ip_data
    };
};

const mapDispatchToProps = {
    start_loader,
    getAWSImages
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CFLandingPage);
