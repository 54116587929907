import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
import Form from "@rjsf/core";
import { Player } from "video-react";
import { toast } from "react-toastify";

import * as constand from "../../constant";
import { setNewUser, saveUserFitnessTest, getUserFitnessTestScore, isTakeTestClicked, fitnessTestModalOpen, fitnessTestForm, getCountOfData, fetchUserDetails, fetchUserOtherDetails, isTestTakenToday } from "../../actions";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { commonService } from "../../_services";

class LandingModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.popupContentRef = React.createRef();
        this.state = {
            isRead: false,
            takeTestOpen: false,
            notNowClicked: false,
            Loading: false,
            isSaveClicked: false,
            testScore: null,
            testScore2: null,
            testScore3: null,
            testScore4: null,
            testScoreTotal: 0,
        };
        this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
        this.returnClass = this.conditonClassList.reverse();
        this.uiSchema = {}
        this.scrollToEnd = this.scrollToEnd.bind(this);
    }

    getColorClass(key) {
        if (this.returnClass.length > 0)
            return this.returnClass[key];
    }
    onCloseModal() {
        this.state.testScore = null;
        this.state.testScore2 = null;
        this.state.testScore3 = null;
        this.state.testScore4 = null;
     }
    conditionHasHomePage = () => {
        return constand.HOME_PAGE.includes(this.props.condition);
    }
    
    componentDidMount() {
        this.props.fitnessTestForm()
        if (this.props.is_test_clicked_by_popup) {
            this.setState({ takeTestOpen: false, notNowClicked: false })
        }
        let fitnessPopupClosed = JSON.parse(localStorage.getItem('fitnessPopupClosed'));
        if (!fitnessPopupClosed && this.props.is_test_clicked_by_popup) {
            this.setState({ takeTestOpen: false, notNowClicked: false })
        }
        let firstAppEntry = JSON.parse(localStorage.getItem("firstAppEntry"))
        if (!firstAppEntry && !this.props.is_test_clicked_by_popup) {
            this.setState({ takeTestOpen: true, notNowClicked: false })
        }
        this.updateUiSchema();
    }

    componentDidUpdate() {
        if (this.props.module_data.ondemand || this.props.module_data.liveclass || this.props.module_data.program) {
            this.scrollToEnd();
        }
    }

    componentWillReceiveProps() {
        let firstAppEntry = JSON.parse(localStorage.getItem("firstAppEntry"))
        if (!firstAppEntry && !this.props.is_test_clicked_by_popup) {
            this.setState({ takeTestOpen: true, notNowClicked: false, isSaveClicked: false })
        }
        this.updateUiSchema();
    }

    setNewUser = (url) => {
        this.onCloseModal();
        this.props.setNewUser(false)
        this.setState({ takeTestOpen: true, notNowClicked: false })
        if ((this.props.is_fitness_test_model_open || this.props.logged_userData.isUserFitnessTest) && this.props.type == "fitness") {
            this.props.fitnessTestModalOpen(false);
            let takeTestFlagObj = {
                isTakeTestClicked: false,
                clickedByTestPopup: false
            }
            this.props.isTakeTestClicked(takeTestFlagObj);
            localStorage.setItem("fitnessPopupClosed", true)
            let firstAppEntry = JSON.parse(localStorage.getItem("firstAppEntry"))
            if (firstAppEntry) {
                if (url) {
                    let urlPage = url.includes("/home") ? "/home" : url + this.props.condition;
                    this.props.history.push(urlPage);
                }
                window.location.reload(true);
                localStorage.setItem("firstAppEntry", false)
            }
        } else if (this.props.type == "landing") {
            localStorage.setItem("firstAppEntry", false)
            if (url) {
                let urlPage = url.includes("/home") ? "/home" : url + this.props.condition;
                this.props.history.push(urlPage);
            }
            window.location.reload(true);
        }
    }

    takeTest = () => {
                if (!this.props.fitness_test_form || !Object.keys(this.props.fitness_test_form).length) {
            let takeTestFlagObj = {
                isTakeTestClicked: true,
                clickedByTestPopup: true
            }
            this.setState({ Loading: true })
            this.props.isTakeTestClicked(takeTestFlagObj);
            this.props.fitnessTestForm().then(response => {
                this.setState({ Loading: false })
            });
        }
        this.props.fitnessTestModalOpen(true);
        this.setState({ takeTestOpen: true, notNowClicked: false })
    }

    scrollToEnd = () => {        
        const element = document.querySelector('#elementToScroll');
        if (element) {
            const offset = element.offsetTop + 200;
            window.scrollTo({ top: offset, behavior: 'smooth' });
            element.scrollIntoView({ behavior: 'smooth' });
            // window.scroll(0, offset);
        }
    };

    notNowTest = () => {
        this.setState({ notNowClicked: true, takeTestOpen: false })
        setTimeout(() => {
            this.scrollToEnd();
        }, 1000);
        let condition = Cookies.get('condition') ? Cookies.get('condition') : this.props.init_condition;
        if ((this.props.module_data && !Object.keys(this.props.module_data).length) && condition) {
            this.props.fetchUserDetails().then(
                () => {
                    this.props.fetchUserOtherDetails().then(() => {
                        this.props.getCountOfData(commonService.replaceChar(condition, false));
                    })
                })
        }
        
    }

    isSkipClick = () => {
        this.onCloseModal();
        if (this.props.is_test_clicked_by_popup) {
            this.setState({ takeTestOpen: false, notNowClicked: true })
        } else {
            this.setNewUser("/home")
        }
    }

    renderLandingContent = () => {
        return (
            <>
                <div className="row mx-auto justify-content-center welcome-popup-cards">
                    {this.props.module_data.program > 0 &&
                        <div className="col-md-4 col-lg-4 col-12 pr-0 mb-3">
                            <div className="text-left card p-3 border-0 bg-yellow d-flex h-100 bg-transparent">
                                <div className="card-body p-0">
                                    <h4 className="font-semibold fontsz-24 ash-txt letter-spacing001 mb-3">Programmes</h4>
                                    <p className="font-qregular fontsize-18 ash-txt letter-spacing001">Check out our different programmes curated for specific health needs.</p>
                                </div>
                                <div className="card-footer bg-transparent p-0 border-0 text-center">
                                    <Link to={"#"}
                                        onClick={() => { this.setNewUser("/programmes/") }}
                                        className="btn btn-darkblue-inverse">Go to programmes</Link>
                                </div>
                            </div>
                        </div>
                    }
                    {this.props.module_data.liveclass > 0 &&
                        <div className="col-md-4 col-lg-4 col-12 pr-0 mb-3">
                            <div className="text-left card p-3 bg-orange1 border-0 d-flex h-100 bg-transparent">
                                <div className="card-body p-0">
                                    <h4 className="font-semibold fontsz-24 white-txt letter-spacing001 mb-3">Live class schedule</h4>
                                    <p className="font-qregular fontsize-18 white-txt letter-spacing001">Take part in our interactive live classes where you can connect with our instructors and other Beam Members.</p>
                                </div>
                                <div className="card-footer bg-transparent p-0 border-0 text-center">
                                    <Link to={"#"}
                                        onClick={() => { this.setNewUser("/liveClasses/") }}
                                        className="btn btn-darkblue-inverse">Go to live classes</Link>
                                </div>
                            </div>
                        </div>
                    }
                    {this.props.module_data.ondemand > 0 &&
                        <div className="col-md-4 col-lg-4 col-12 pr-0 mb-3">
                            <div className="text-left bg-purple card p-3 border-0 d-flex h-100 bg-transparent">
                                <div className="card-body p-0">
                                    <h4 className="font-semibold fontsz-24 white-txt letter-spacing001 mb-3">On-demand library</h4>
                                    <p className="font-qregular fontsize-18 white-txt letter-spacing001">Why not browse our selection of exercise, education and well-being sessions for you to do anytime, anywhere.</p>
                                </div>
                                <div className="card-footer bg-transparent p-0 border-0 text-center">
                                    <Link to={"#"}
                                        onClick={() => { this.setNewUser("/on-demand/") }}
                                        className="btn btn-darkblue-inverse">Go to on-demand</Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {this.conditionHasHomePage() &&
                    <div className="row m-0 w-100 my-5 text-center">
                        <Link to={"#" + this.props.condition}  id="elementToScroll"
                            onClick={() => { this.setNewUser("/home") }}
                            className="yellow-button mx-auto"
                        >Go to homepage</Link>
                    </div>
                }
                <div  id="elementToScroll"></div>
            </>
        )
    }

    renderFitnessContent = () => {
        return (
            <>
                <div className="row mx-auto justify-content-center">
                    <p className="font-18 font-qregular text-center white-txt welcome-popup-body-text">
                        Before you continue we’d encourage you to complete a 1 minute exercise to measure your current fitness level and help you track your progress.  Just head to “My Beam” to complete the test every 12 weeks or so and see how you are getting on.
                    </p>
                </div>

                {this.props.hasButton &&
                    <div className="buttons-read w-100  m-t-10 m-b-40 read-btn-mob text-center pointer">
                        <a onClick={() => this.notNowTest()} className="btn btn-yellow-nobg fs-14 padding-taketest read-now m-r-20 mobile-test-button" >
                            Not now
                        </a>
                        <a onClick={this.takeTest} className="btn btn-yellow fs-14 padding-taketest read-later m-l-20 pointer mobile-test-button take-test-margin-mob" >
                            Take the test
                        </a>
                    </div>
                }
            </>
        )
    }

    log = (type) => console.log.bind(console, type);
    customFieldTemplate = (props) => {
    console.log('fitnesstest customFieldTemplate-props', props)
        const { id, classNames, label, help, required, description, errors, children, rawErrors, displayLabel } = props;

        if (this.props.fitness_test_form && this.props.fitness_test_form?.id === 2) {
            const isQuestion = ['root_testScore', 'root_testScore2', 'root_testScore3', 'root_testScore4'].includes(id);

            const regex = /\(([^)]+)\)/;
            const match = regex.exec(label);
            let wrappedText;

            if (match && isQuestion) {
                const wrapped = label.replace(match[0], `<span class="fitness-popup font-16 bold-text">${match[0]}</span>`);

                wrappedText = wrapped;
            } else {
                wrappedText = label;
            }
            console.log("wrappedText",wrappedText)
            return (
                <div
                  className={
                    classNames + " float-left fitness-popup text-center-mob-view"
                  }
                >
                  {id != "root" && displayLabel && (
                    <label
                      htmlFor={id}
                      className={
                        " white-txt px-0 " +
                        (isQuestion ? "col-md-12 fitness-ques" : "col-md-12")
                      }
                    ><>
                      <span
                        className={
                          "font-22 font-qregular white-txt " +
                          (isQuestion ? "bold-text" : "")
                        }
                        dangerouslySetInnerHTML={{ __html: wrappedText }}   
                      ></span>
                      </>
                      <span className="orangefont">{required ? " *" : null}</span>
                      <p className="orangefont"> {rawErrors} </p>
                    </label>
                  )}
                  <p>{description}</p>
                  {children}
                  {help}
                </div>
              );
        } else {
            return (
                <div className={classNames + ' float-left fitness-popup text-center-mob-view'}>
                    {id != 'root' && displayLabel &&
                        <label htmlFor={id} className={" white-txt px-0 " + (id == "root_testScore" ? "col-md-12 fitness-ques" : "col-md-12")}>
                            <span className={"font-18 font-qregular white-txt " + (id === "root_testScore" ? "bold-text" : '')} dangerouslySetInnerHTML={{ __html: label }} />
                            <span className="orangefont">{required ? " *" : null}</span>
                            <p className="orangefont"> {rawErrors} </p>
    
                        </label>
                    }
                    <p>{description}</p>
                    {children}
                    {help}
                    {id === "root_testScore" && id != 'root' && displayLabel &&
                        <div className="fitness-save-btn text-center">
                            <button
                                disabled={this.state.isSaveClicked}
                                className="btn btn-yellow font-18 read-later pointer font-semibold mb-0" type="submit" >
                                Save
                            </button>
                            <span className="font-14 white-txt text-center or-text m-0 font-semibold">or</span>
                            <a href="#" onClick={() => this.isSkipClick()} className="font-14 white-txt text-center underline-text pl-1 pointer font-semibold">
                                Skip
                            </a>
                        </div>
                    }
                </div>
            );
        }
    }

    //custom date picker
    videoPlayer = (props) => {
        console.log('videplayer---', props)
        return (
            <div>
                <Player
                    ref={player => {
                        this.player = player;
                    }}
                    id="myVideo"
                    autoPlay={false}
                    className="w-100 border-0"
                    height="400"
                    playsInline
                    /* onPlay={this.palyVideo}
                    onPause={this.pauseVideo}
                    onClick={this.showClockTimer()} */
                    /* poster={
                     constand.WORKOUT_IMG_PATH+
                     ondemand_view_detail.id +
                     "-img.png"
                   } */
                    poster={props.schema.thumbnail ? props.schema.thumbnail : constand.WEB_IMAGES + "ondemand-placeholder.png"}
                    src={props.schema.video}
                ></Player>
            </div>
        );
    }

    submitForm(submittedData) {
        this.setState({ isSaveClicked: true })
        this.props.setNewUser(false)
        localStorage.setItem('fitnessPopupClosed', true)
        console.log('submitForm', submittedData.formData);
        const properties =JSON.parse(this.props.fitness_test_form.schema)
        let formId = 0
        if (this.props.fitness_test_form) {
            formId = parseInt(this.props.fitness_test_form?.id)
        }
        let params;
        if (this.props.fitness_test_form?.id == 2) {
            let testScores=[]
            testScores.push({title:properties.properties.testScore2.title,testScore:this.state.testScore2})
            testScores.push({title:properties.properties.testScore3.title,testScore:this.state.testScore3})
            testScores.push({title:properties.properties.testScore4.title,testScore:this.state.testScore4})
            let jsonObj={testScores:testScores,
                testScore: this.state.testScore2 * this.state.testScore3}
                console.log("jsonObj",jsonObj)
          params = [
            {
              formData: {testScore: submittedData.formData.testScore},
              formId: 2,
            },
            {
              formData: jsonObj,
              formId: 4,
            },
          ];
        } else {
          params = [{
            formData: submittedData.formData,
            formId: formId,
          }];
        }
        if (!this.props.is_test_clicked_by_popup) {
            this.props.fitnessTestModalOpen(false);
        }
        this.props.saveUserFitnessTest({ data: params }).then(response => {
            this.props.isTestTakenToday(true);
            if (!this.props.is_test_clicked_by_popup) {
                this.modelClose()
            }
            toast.success(response.message);
            this.setState({ formData: {} })
        }, error => {
            toast.error(error)
            this.setState({ formData: {} })
        })
    }

    modelClose = () => {
        this.props.fitnessTestModalOpen(false);
        this.props.getUserFitnessTestScore();
        this.props.isTakeTestClicked(false);
        this.props.isTestTakenToday(false);
    }

    transformErrors = (errors) => {
        let schema = Object.keys(this.props.fitness_test_form).length ? JSON.parse(this.props.fitness_test_form?.schema) : {};
        return errors.map(error => {
            let propertyName = error.property.replace('.', '')
            if (schema.properties[propertyName].messages && schema.properties[propertyName].messages[error.name]) {
                console.log('message', schema.properties[propertyName].messages[error.name])
                return {
                    ...error,
                    message: schema.properties[propertyName].messages[error.name]
                };
            } else if (!schema.properties[propertyName].minimum && schema.properties[propertyName]) {
                if (schema.properties[propertyName].messages?.required) {
                    return {
                        ...error,
                        message: schema.properties[propertyName].messages?.required
                    };
                } else {
                    return {
                        ...error,
                        message: "Please provide a valid number"
                    };
                }
                
            }
            return error;
        });
    }

    calculateTotal = () => {
        const { testScore2, testScore3 } = this.state;

        if (testScore2 && testScore3) {

            return testScore2 * testScore3;
        } 
        return 0;
    }

    changedFormData = (params) => {
        if (this.props.fitness_test_form?.id == 2) {
            this.setState({testScore: params.formData.testScore});
            this.setState({testScore2: params.formData.testScore2});
            this.setState({testScore3: params.formData.testScore3});
            this.setState({testScore4: params.formData.testScore4});
        }
    }

    renderFitnessForm = () => {
        const item = this.props.fitness_test_form && Object.keys(this.props.fitness_test_form).length ? JSON.parse(this.props.fitness_test_form?.schema) : {};
        const testTitle = (this.props.fitness_test_form && Object.keys(this.props.fitness_test_form).length && this.props.is_test_clicked_by_popup) && this.props.fitness_test_form?.name;

        if (this.props.fitness_test_form?.id == 2) {
            item.properties['testScore'].default = this.state.testScore;
            item.properties['testScore2'].default = this.state.testScore2;
            item.properties['testScore3'].default = this.state.testScore3;
            item.properties['testScore4'].default = this.state.testScore4;
        }
        return (
          <>
            {this.props.fitness_test_form &&
              Object.keys(this.props.fitness_test_form).length && (
                <>
                  <div className="text-center w-100">
                    {this.state.Loading && <AnimateLoaderComponent />}
                  </div>
                  {this.props.is_test_clicked_by_popup && (
                    <h5
                      className="col-md-12 p-0 font-semibold white-txt test-heading letter-spacing003 mb-4 mob_wid_title test-title ml-mob-test-title"
                      id="exampleModalLabel font-medium"
                    >
                      {testTitle}
                    </h5>
                  )}
                  <Form
                    className="m-b-50 fitness-form"
                    noHtml5Validate={true}
                    safeRenderCompletion={true}
                    schema={item}
                    onChange={(params) => {
                      this.changedFormData(params);
                    }}
                    onSubmit={(params) => {
                      this.submitForm(params);
                    }}
                    onError={this.log("errors")}
                    transformErrors={this.transformErrors}
                    showErrorList={false}
                    uiSchema={this.uiSchema}
                    FieldTemplate={this.customFieldTemplate}
                    // widgets={this.widgets}
                    //formData={this.state.formData}
                    omitExtraData={true}
                    liveOmit={true}
                  >
                    {this.props.fitness_test_form?.id == 2 && (
                      <>
                        <p className="font-22 text-center font-semibold white-txt m-b-30">
                          Your total minutes of physical activity is{" "}
                          {this.calculateTotal()} minutes per week
                        </p>

                        <div className="fitness-save-btn text-center m-0">
                          <button
                            disabled={this.state.isSaveClicked}
                            className="btn btn-yellow font-18 read-later pointer font-semibold mb-15"
                            type="submit"
                          >
                            Save
                          </button>
                          <div className="or-btn">
                            <span className="font-14 white-txt text-center or-text m-0 font-semibold">
                                or
                            </span>
                            <a
                                href="#"
                                onClick={() => this.isSkipClick()}
                                className="font-14 white-txt text-center underline-text pl-1 pointer font-semibold"
                            >
                                Skip
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </>
              )}
          </>
        );
    }

    renderTitle = () => {
        let authData = JSON.parse(localStorage.getItem('userDetails'));
        const modalTitle = (this.props.fitness_test_form && Object.keys(this.props.fitness_test_form).length && !this.props.is_test_clicked_by_popup) ? this.props.fitness_test_form?.name : this.state.isSaveClicked ? "Great Work " + authData.name + "!" : "Welcome to Beam!";
        return (
            <>
                <h5 className="text-center col-md-12 p-0 font-semibold white-txt fontsize-36 letter-spacing003 mt-5 mb-4 welcome-popup-title" id="exampleModalLabel font-medium">{modalTitle}
                </h5>
                {this.props.is_test_clicked_by_popup && !this.state.isSaveClicked &&
                    <p className="mb-4 text-center col-md-12 p-0 white-txt fontsize-24 letter-spacing001 font-semibold welcome-popup-subtitle">We’re so pleased to have you here!</p>
                }
            </>
        )
    }

    renderFitnessCompleteContent = () => {
        return (
            <div className="row mx-auto justify-content-center">
                <p className="font-24 great-work-content font-qregular text-center white-txt">
                    Recording this information on a regular basis will help you track your progress on (and off) Beam.
                </p>
            </div>
        )
    }

    updateUiSchema = () => {
        this.uiSchema = {
            desc: {
                classNames: "col-md-6 font-qregular font-18"
            },
            testScore: {
                classNames: this.props.fitness_test_form?.id === 2 ? "col-md-12 font-24 font-bold hide-arrows" : "col-md-10 font-24 font-bold hide-arrows",
                "ui:placeholder": "0",
                "type": "number"
            },
            testScore2: {
                classNames: "col-md-12 font-24 font-bold hide-arrows",
                "ui:placeholder": "0",
                "type": "number"
            },
            testScore3: {
                classNames: "col-md-12 font-24 font-bold hide-arrows",
                "ui:placeholder": "0",
                "type": "number"
            },
            testScore4: {
                classNames: "col-md-12 font-24 font-bold hide-arrows",
                "ui:placeholder": "0",
                "type": "number"
            },
            s3Video: {
                "ui:options": {
                    label: false,
                },
                classNames: this.props.type == "fitness" && this.props.is_test_clicked_by_popup && !this.state.isSaveClicked ? "col-md-6 video-top fitness-vdo-align" : "col-md-6 video-top fitness-vdo-align-prog",
                "ui:widget": this.videoPlayer,
            },
            secondDesc: {
                classNames: this.props.type == "fitness" && this.props.is_test_clicked_by_popup && !this.state.isSaveClicked ? "col-md-12 font-qregular font-18 second-desc content-align" : "col-md-12 font-qregular font-18 second-desc content-align-prog"
            }
        }
    }

    render() {
        let currentCondition = Cookies.get('condition')
        let fitnessPopupClosed = JSON.parse(localStorage.getItem('fitnessPopupClosed'));

        return (
            <Modal
                className="removebbg-popup "
                open={this.props.isModalOpen}
                center
            >
                <div className={"modal-dialog welcome-popup-lg m-t-80 " + (this.props.type === "fitness" ? "welcome-test-popup" : '')}
                    role="document" ref={this.popupContentRef}
                >
                    <div className="modal-content bg-transparent border-0">
                        <div className="modal-body pb-0 pt-0 bg-darkblue-with-border each_programs_height">
                            <button
                                type="button"
                                className="big-white-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { this.setNewUser() }}
                            >&times;
                            </button>
                            <div className="col-md-12 p-0">
                                <div className="row">
                                    <div className="position-relative w-100">
                                        {this.renderTitle()}

                                        {/* Fitness test intro content */
                                            this.props.type == "fitness" && this.props.is_test_clicked_by_popup && !this.state.isSaveClicked && this.renderFitnessContent()
                                        }
                                        {this.props.type == "fitness" && this.props.is_test_clicked_by_popup && this.state.isSaveClicked &&
                                            this.renderFitnessCompleteContent()
                                        }

                                        {((this.props.type === "landing") || ((this.props.type === "fitness" && this.state.notNowClicked && !fitnessPopupClosed)) || (this.state.isSaveClicked)) &&
                                            <div className={(this.state.isSaveClicked ? "m-t-50" : "m-t-90")}>
                                                <p className="mb-4 text-center col-md-12 p-0 font-semibold white-txt fontsize-24 letter-spacing001 mob_wid welcome-doll-font">Can we help you find what you’re looking for?</p>
                                                <span className="dollimg_rightside"><img src={constand.WEB_IMAGES + "Asset-4.png"} className="img-fluid welcome-doll" alt="" /></span>
                                            </div>
                                        }
                                    </div>
                                    { /* Landing content for without test condition */
                                        this.props.type == "landing" && this.renderLandingContent()
                                    }

                                    {/* Dynamic form fields */
                                        this.props.type == "fitness" && this.state.takeTestOpen && !this.state.isSaveClicked && this.renderFitnessForm()
                                    }

                                    {/* Landing content for not now click */
                                        this.props.type == "fitness" && this.props.is_test_clicked_by_popup && ((this.state.notNowClicked && !fitnessPopupClosed) || this.state.isSaveClicked) && this.renderLandingContent()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        module_data: state.header.module_data,
        fitness_test_form: state.dashboard.fitness_test_form,
        is_take_test_clicked: state.dashboard.is_take_test_clicked,
        is_test_clicked_by_popup: state.dashboard.is_test_clicked_by_popup,
        logged_userData: state.header.logged_userData,
        is_fitness_test_model_open: state.dashboard.is_fitness_test_model_open,
        init_condition: state.auth.initial_condition
    };
};
const mapDispatchToProps = {
    setNewUser, saveUserFitnessTest,
    getUserFitnessTestScore, isTakeTestClicked, fitnessTestModalOpen, fitnessTestForm, getCountOfData, fetchUserDetails, fetchUserOtherDetails,
    isTestTakenToday
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingModalComponent);
