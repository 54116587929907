import React from 'react';
import { connect } from 'react-redux';
import * as constand from "../../constant";
import { clearRegisterFormUpdate, setMasterUserId, impersonateAccount, fetchUserDetails, setAllUserList, healthCondition } from "../../actions";
import { Cookies } from "react-cookie-consent";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';

class ChildProfileRegisterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            childUserList: []
        }
    }
    componentDidMount() {
        console.log('30-this.props', this.props)
        /* if (!this.props.registerFormvalues.masterUserID) {
            localStorage.setItem("masterRegVal", JSON.stringify(this.props.registerFormvalues))
        } */
        //child user list
        if (this.props.registerUserList) {
            let childUserList = this.props.registerUserList.filter(user => user.masterUserID)
            this.setState({ childUserList: childUserList })
        }
    }
    
    addNewChildProfile = () => {
        console.log('this.props.chi;profileregister')
       // this.props.clearRegisterFormUpdate();
            console.log('then - ', this.props.registerFormvalues)
            console.log('this.props.masterAccountDetails', this.props.masterAccountDetails)
            if (this.props.masterAccountDetails.length > 0) {
                let masterId =  this.props.masterAccountDetails[0].userid || null;
                let encryptedString = null;
                if(masterId){
                    encryptedString = CryptoJS.AES.encrypt(masterId.toString(), 'myTotallySecretKey').toString();
                    encryptedString = encryptedString.replace(/\//g, '_');
                }
                //set master userid
                this.props.setMasterUserId(encryptedString)
                this.props.registerFormvalues.masterUserID = encryptedString;
                this.props.registerformUpdate(this.props.registerFormvalues)
                this.props.healthCondition(true)
                console.log('childMasterID', this.props.registerFormvalues)
            }
            this.props.setStep(4)
       // });


    }
    getStartedAccount = async () => {
        let masterRegVal = JSON.parse(localStorage.getItem('masterRegVal')) || this.props.registerUserList[0]
        await this.props.impersonateAccount(masterRegVal.email)
        let conditions = masterRegVal.health_condition[0].tag;
        let url = Cookies.get('current-page-url') ? Cookies.get('current-page-url') : "/on-demand/" + conditions;
        const { from } = { from: { pathname: url } };
        this.props.history.push(from);
        console.log("this.props.responseMsg",this.props)
        if(this.props.responseMsg){
        toast.success(this.props.responseMsg);
        }
        localStorage.setItem("firstAppEntry", true)
    }

    closeModal = async () => {
        let masterRegVal = JSON.parse(localStorage.getItem('masterRegVal')) || this.props.registerUserList[0]
        await this.props.impersonateAccount(masterRegVal.email)
        const { from } = { from: { pathname: '/home' } };
        this.props.history.push(from);
    }

    render() {
        return (
            <div className="step12 close_back_icon_align">
                {this.props.pageFrom != 'mycondition' &&
                    <h3 className="text-center ">Would you like to add any child profiles to your account?<span
                        onClick={() => this.closeModal()}
                        className="close-register orangefont"
                    >
                        X
                    </span></h3>
                }
                <div className="row justify-content-center align-items-center">
                    <div className="input_section col-md-8 m-t-20">
                        <div className="form-group row">
                            <p className="col-md-12 font-qmedium">If you'd like your child to use Beam it's best to set them up with their own profile. They may be eligible for free access based on their age, location or clinic. It also means we can track their activity on Beam separately to yours to give more accurate feedback and recommendations. </p>
                        </div>
                        {this.props.registerUserList && <div className="col-md-12 col-lg-12 mb-3 New_account_details p-0">
                            <ul
                                className="nav nav-pills flex-row border-right account_Tabs_new w-100 border-0 child_prof_reg p-0"
                                id="myTab"
                                role="tablist"
                            >
                                {/* masterprofile section */}
                                {<li className="nav-item">
                                    <button
                                        className="active nav-link font-medium font-21 m-b-10 btn_bg"
                                    >
                                        <span className="profile_name">You</span>
                                        <span className="profile_img"><img src={constand.WEB_IMAGES + "no-profile-pic.png"} alt="" /></span>

                                    </button>
                                </li>
                                }
                                {/* child profile section */}
                                {this.state.childUserList && this.state.childUserList.map((user, i) => {
                                    return (
                                        <li className="nav-item">
                                            <button
                                                className="active nav-link font-medium font-21 m-b-10 btn_bg"
                                            >
                                                <span className="profile_name">{user.firstname}</span>
                                                <span className="profile_img"><img src={constand.WEB_IMAGES + "no-profile-pic.png"} alt="" /></span>
                                            </button>
                                        </li>
                                    )
                                })
                                }
                                {<li className="nav-item">
                                    <button
                                        className="nav-link font-medium font-21 m-b-30"
                                        onClick={() => this.addNewChildProfile()}
                                    >
                                        <span className="profile_name"></span>
                                        <span className="profile_img plus_profile_img"><i className="fa fa-plus"></i></span>
                                    </button>
                                </li>
                                }
                            </ul>
                        </div>
                        }
                        <div id="register-link" className=" w-100 m-t-20 m-b-40 float-left">
                            <span onClick={() => this.getStartedAccount()} className="bluebtn float-left font-medium font-14 w-100 text-center pointer">All done - let's get started!</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        registerUserList: state.register.set_all_user_list,
        masterAccountDetails: state.register.set_signup_user_list,
        reduxRegisterFormvalues: state.register.registerFormValues
    };
};

const mapDispatchToProps = {
    clearRegisterFormUpdate, setMasterUserId, impersonateAccount, fetchUserDetails, setAllUserList, healthCondition
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChildProfileRegisterComponent);
