import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import ContactForm from "./ContactFormComponent";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            users: []
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const { user, users } = this.state;
        return (
            <section className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'contactcontent' : 'contactcontent m-t-80'}>
                <Helmet>
                    <title>{constand.HELP_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.HELP_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.HELP_DESC} />
                    <meta property="og:image" content={constand.HELP_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.HELP_PAGE_IMAGE_ALT} />
                </Helmet>
                <div className="section-1">
                    <div className="">
                        <div className="col-md-12 ml-auto mr-auto">
                            <h5 className="font-37 white-txt font-medium m-t-10">
                                Contact Us
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="section-2 p-b-0">
                    <div className="col-md-4 ml-auto mr-auto">
                        <div className="socialicons">
                            <ul className="text-center">
                                <li><a href="https://twitter.com/beamfeelgood" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/beamfeelgood" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                <li><a href="https://www.facebook.com/feelgoodbeam" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/beam_cf" target="_blank"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*}<div className="section-3">
                <h5 className="email-us font-30 font-semibold">
                    Email
                </h5>
                <div className="emailus">
                     <span className="leftlabel-red font-qregular font-18">Technical questions</span>
                     <span className="rightlabel-grey font-qregular font-18"><img src="/images/hypen.png" alt="" /> <u>help@beam.co.uk</u></span>
                </div>
                <div className="emailus">
                     <span className="leftlabel-red font-qregular font-18">Press & Media</span>
                     <span className="rightlabel-grey font-qregular font-18"><img src="/images/hypen.png" alt="" /> <u>help@beam.co.uk</u></span>
                </div>
                <div className="emailus">
                     <span className="leftlabel-red font-qregular font-18">Beam for work</span>
                     <span className="rightlabel-grey font-qregular font-18"><img src="/images/hypen.png" alt="" /> <u>help@beam.co.uk</u></span>
                </div>
            </div>*/}
                <div className="section-4 p-t-0">
                    <div className="col-md-6 ml-auto mr-auto">
                        <h5 className="form-heading font-30 font-semibold">Fill in the form, it's easy!</h5>
                        {/* <p className='font-14 complaint-text font-book'>Got a complaint? Please email us directly on <a href="mailto:complaints@beamfeelgood.com"> complaints@beamfeelgood.com</a> rather than using the form below and we will endeavour to get back to you within 1 working day.</p> */}
                        <ContactForm location={this.props.location} from="contact" />
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        close_banner: state.header.close_banner,
        close_banner_kd: state.header.close_banner_kd,
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactPage);
